import axios from './auth'

const CREWING_BASE_URL = `${process.env.VUE_APP_API_CREWING_BASE_URL}`
const BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}`
const BASE_PORTAL = `${process.env.VUE_APP_API_BASE_PORTAL}`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchMaterPajak({ commit }, params) {
    const url = `${CREWING_BASE_URL}/master-ptkp`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchExpiredNotifCrew({ commit }, params) {
    const url = `${CREWING_BASE_URL}/expired-notif-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async fetchExpiredNotifCOPCOC({ commit }, params) {
    const url = `${CREWING_BASE_URL}/expired-notif-cop-coc`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async exportExpiredNotifCrew({ commit }, params) {
    const url = `${CREWING_BASE_URL}/expired-notif-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  async exportExpiredNotifCOPCOC({ commit }, params) {
    const url = `${CREWING_BASE_URL}/expired-notif-cop-coc`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  
  async fetchLisBloodType({ commit }, params) {
    const url = `${CREWING_BASE_URL}/bloodtype`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchCrewList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCertificateList({ commit }, params) {
    const url = `${BASE_PORTAL}/certificate-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchEducationList({ commit }, params) {
    const url = `${BASE_PORTAL}/educations`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getCrewDetail({ commit }, id) {
    const url = `${CREWING_BASE_URL}/detail-crew/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCandidateList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-candidate`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchBlackList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-blacklist`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createCandidate({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/create-candidate`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
  };
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async createCrew({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/create-crew`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
  };
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchProvience({ commit }, params) {
    const url = `${CREWING_BASE_URL}/provinces`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCities({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/cities`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchDistricts({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/districts`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchVillages({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/villages`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchVehicles({ commit }, params = {}) {
    const url = `${BASE_URL}/vehicles`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCompanies({ commit }, params = {}) {
    const url = `${BASE_URL}/companies`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchEmployeeStatus({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/employee-status`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchWorkArea({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/work-area`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPorts({ commit }, params = {}) {
    const url = `${BASE_URL}/ports`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPayrollAllowance({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/payroll-allowance`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async fetchUserExpert({ commit }, params = {}) {
    const url = `${BASE_URL}/users`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPositionList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-position`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchTypePositionList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/master-type-position`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchPayrollSettingBPJS({ commit }, params) {
    const url = `${CREWING_BASE_URL}/payroll-company-work-area/${params.id}/payroll-setting-bpjs`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params?.params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async fetchBlacklistReasons({ commit }, params) {
    const url = `${CREWING_BASE_URL}/blacklist-reason`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getProfileCandidate({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/profile-candidate/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async setVerificationDocument({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/verification-kemenhub/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setBlacklistCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/blacklist-candidate/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setBlacklistCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/blacklist-crew/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setInterviewCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/set-interview/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setSignOnCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/sign-on/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setSwapPosition({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/swap-position/${payload.emp_crewing_id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchPointInterview({ commit }, params) {
    const url = `${CREWING_BASE_URL}/point`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchCriteriaInterview({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/interview-criteria`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setFormInterview({}, payload = {}) {
    const url = `${CREWING_BASE_URL}/form-interview/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async medicalCheckup({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/medical-check-up/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async masterDocumentList({ commit }, params = {}) {
    const url = `${BASE_PORTAL}/master-document-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async masterSignOffReasons({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/master-signoff-reason`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setDocumentChecklist({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/document-checklist/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchMasterDepartmentList({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/department`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  async fetchDashboardCrew({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/dashboard-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchInductionChecklist({ commit }, params) {
    const url = `${CREWING_BASE_URL}/induction-checklist/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setInductionCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/induction-checklist/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setSignOffCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/sign-off/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setRequestSignOff({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/sign-off/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async changeStatusCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/change-status/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.change_status,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchConditeModel({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/condite/model`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setConditeCrew({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/condite`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchConductModel({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/conduct/model`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchDownloadPKL({ commit }, params) {
    const url = `${CREWING_BASE_URL}/download-pkl/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      responseType: 'blob',
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setConductCrew({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/conduct`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },  
  
  async setNoticeSignOff({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/notice-sign-off/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async updateDataDiri({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-or-update-data-diri/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateJoinDateCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/edit-join-date/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateAlamat({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-or-update-alamat/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  // CRUD KELUARGA
  async createKeluarga({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-keluarga/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateKeluarga({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/update-keluarga/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteKeluarga({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-keluarga/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async deleteCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-crew/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  
  async deleteCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-candidate/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  
  async deleteFleetHistory({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-position/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

 // CRUD PEKERJAAN
  async createPekerjaan({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-pekerjaan/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updatePekerjaan({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/update-pekerjaan/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deletePekerjaan({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-pekerjaan/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // CRUD TRAVEL DOKUMEN
  async createTravelDokumen({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-travel-dokumen/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateTravelDokumen({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/update-travel-dokumen/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteTravelDokumen({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-travel-dokumen/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  
  // CRUD COMPETENCY
  async createCompetency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-competency/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateCompetency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/update-competency/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteCompetency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-competency/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // CRUD PROFICIENCY
  async createProficiency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-proficiency/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateProficiency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/update-proficiency/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteProficiency({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-proficiency/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // CRUD PROFICIENCY
  async createOrUpdateDokumenPendukung({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/create-or-update-dokumen-pendukung/${payload.id}`

    const method = 'post'
    const headers = {
      "Content-Type": "multipart/form-data"
    };
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteDokumenPendukung({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/delete-dokumen-pendukung/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // CERTIFICATE LIST
  async fetchCertificateList({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/certificate-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setCertificateList({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/certificate-list`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateCertificateList({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/certificate-list/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteCertificateList({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/certificate-list/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // REASONS SIGN OFF
  async fetchReasonSignOff({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/reason-sign-off`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setReasonSignOff({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/reason-sign-off`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateReasonSignOff({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/reason-sign-off/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteReasonSignOff({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/reason-sign-off/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

    // MASTER POSITIONS
    async fetchMasterPosition({ commit }, params = {}) {
      const url = `${CREWING_BASE_URL}/master-position`
  
      const method = 'get'
      const headers = {}
      const config = {
        url,
        method,
        headers,
        params,
      }
  
      try {
        const response = await axios(config)
        let data = response.data.data
        return {
          status: 'success',
          data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          data: e.response.data.data,
        }
      }
    },
    async setMasterPosition({ commit }, payload = {}) {
      const url = `${CREWING_BASE_URL}/master-position`
  
      const method = 'post'
      const headers = {}
      const config = {
        url,
        method,
        headers,
        data: payload.data,
      }
  
      try {
        const response = await axios(config)
        let data = response.data.data
        return {
          status: 'success',
          data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          data: e.response.data.data,
        }
      }
    },
    async updateMasterPosition({ commit }, payload = {}) {
      const url = `${CREWING_BASE_URL}/master-position/${payload.id}`
  
      const method = 'put'
      const headers = {}
      const config = {
        url,
        method,
        headers,
        data: payload.data,
      }
  
      try {
        const response = await axios(config)
        let data = response.data.data
        return {
          status: 'success',
          data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          data: e.response.data.data,
        }
      }
    },
    async deleteMasterPosition({ commit }, payload = {}) {
      const url = `${CREWING_BASE_URL}/master-position/${payload.id}`
  
      const method = 'delete'
      const headers = {}
      const config = {
        url,
        method,
        headers,
      }
  
      try {
        const response = await axios(config)
        let data = response.data.data
        return {
          status: 'success',
          data,
        }
      } catch (e) {
        return {
          status: e.response.status,
          data: e.response.data.data,
        }
      }
    },

  // EMAIL
  async sendEmailToCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/send-email-candidate/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async sendEmailToInterviewer({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/send-email-interviewer/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // EXPORT
  async exportListCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/export-list-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload.params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  }, 
  async exportListCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/export-list-candidate`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload.params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async exportListBlacklist({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/export-list-blacklist`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload.params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchListBirthdayCrew({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/birthday-of-crew-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async exportListBirthdayCrew({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/birthday-of-crew-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params.params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async fetchListPklCrew({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/crew-pkl-not-yet`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async exportListPklCrew({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/crew-pkl-not-yet`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params.params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data;
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
}
