<template>
  <b-container fluid>
    <form>
      <b-row>
        <b-col lg="3">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Add New User</h4>
            </template>
            <template v-slot:body>
              <b-form-group>
                <div class="add-img-user profile-img-edit">
                  <b-img
                    class="profile-pic height-150 width-150"
                    fluid
                    :src="
                      user.photo
                        | checkPhoto(require('@src/assets/images/user/01.jpg'))
                    "
                    alt="Profile Picture"
                  />
                  <div class="p-image">
                    <b-button
                      variant="none"
                      class="upload-button iq-bg-primary position-relative"
                      :disabled="loading_upload"
                    >
                      <input
                        type="file"
                        class="h-100 position-absolute"
                        accept="image/png, image/jpeg"
                        style="opacity: 0"
                        @change="onChangePhoto"
                      />
                      {{ loading_upload ? 'Uploading...' : 'Upload Photo' }}
                    </b-button>
                  </div>
                </div>
                <div class="img-extension my-3">
                  <div class="d-inline-block align-items-center">
                    <span>Only</span>
                    <b-link href="javascript:void(0);">.jpg</b-link>
                    <b-link href="javascript:void(0);">.png</b-link>
                    <b-link href="javascript:void(0);">.jpeg</b-link>
                    <span>allowed</span>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="*Username:" label-for="uname">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Username"
                  rules="required|max:30"
                >
                  <b-form-input
                    v-model="user.username"
                    type="text"
                    placeholder="Username"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group label="*Email:" label-for="email">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email|max:100"
                >
                  <b-form-input
                    v-model="user.email"
                    type="text"
                    placeholder="Email"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group label="*Password:" label-for="pass">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Password"
                  rules="required|min:6"
                >
                  <b-form-input
                    v-model="user.password"
                    type="password"
                    placeholder="Password"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
              <b-form-group label="*Repeat Password:" label-for="rpass">
                <ValidationProvider
                  v-slot="{ errors }"
                  vid="repeat_password"
                  name="Repeat Password"
                  rules="required"
                >
                  <b-form-input
                    type="password"
                    placeholder="Repeat Password"
                    :class="errors.length > 0 ? ' is-invalid' : ''"
                    v-model="confirm_password"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form-group>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">New User Information</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-form-group class="col-md-6" label="*Name:" label-for="fname">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="name"
                    rules="required|max:100"
                  >
                    <b-form-input
                      v-model="user.name"
                      type="text"
                      placeholder="Name"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="*Mobile Number:"
                  label-for="mobno"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Mobile Number"
                    rules="required|max:30"
                  >
                    <b-form-input
                      v-model="user.phone"
                      type="number"
                      placeholder="Mobile Number"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-sm-6"
                  label-for="gender"
                  label="*Gender:"
                >
                  <b-form-select
                    id="gender"
                    v-model="user.gender"
                    plain
                    :options="genders"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Gender</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="*Birthday:"
                  label-for="birthday"
                >
                  <b-form-input
                    id="birthday"
                    type="date"
                    v-model="user.birthday"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show</small
                  >
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="*Street Address:"
                  label-for="add1"
                >
                  <b-form-textarea
                    id="add1"
                    v-model="user.address"
                    name="add1"
                    placeholder="Street Address"
                  ></b-form-textarea>
                </b-form-group>
              </b-row>
              <hr />
              <h5 class="mb-3">Role & Privilege</h5>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="*User Role:"
                  label-for="selectuserrole"
                >
                  <v-select
                    v-model="user.roleId"
                    label="roles"
                    :options="roles"
                    :reduce="(roles) => roles.id"
                    @input="fetchMenus"
                  ></v-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="*Company Name:"
                  label-for="cname"
                >
                  <v-select
                    v-model="user.companyId"
                    label="company"
                    :options="companies"
                    placeholder="Company Name"
                    :reduce="(c) => c.id"
                  ></v-select>
                </b-form-group>
                <!-- <b-form-group
                  class="col-md-4"
                  label="*Department Name:"
                  label-for="cname"
                >
                  <v-select
                    v-model="user.departmentId"
                    label="name"
                    :options="departments"
                    placeholder="Department Name"
                    :reduce="(d) => d.id"
                  ></v-select>
                </b-form-group> -->

                <b-col cols="12" md="12">
                  <div v-if="loading_menu" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <div v-if="menus.length > 0" class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark text-center">
                          <tr>
                            <th width="5%">No.</th>
                            <th width="35%">Data</th>
                            <th width="10%">Add</th>
                            <th width="10%">Update</th>
                            <th width="10%">Delete</th>
                            <th width="10%">Export</th>
                            <th width="10%">Review</th>
                            <th width="10%">Approval</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(item, key) in menus">
                            <tr :key="`menu-${key}`">
                              <td class="text-center">
                                <b-form-checkbox
                                  v-model="checkedMenus[key].active"
                                  :name="`basic-checkbox-${key}`"
                                  @change="selectMenu(key)"
                                ></b-form-checkbox>
                              </td>
                              <td>{{ item.menu }}</td>
                              <td class="text-center">
                                <template v-if="item.add">
                                  <b-form-checkbox
                                    v-model="checkedMenus[key].add"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="item.update">
                                  <b-form-checkbox
                                    :key="key"
                                    v-model="checkedMenus[key].update"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="item.delete">
                                  <b-form-checkbox
                                    :key="key"
                                    v-model="checkedMenus[key].delete"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="item.export">
                                  <b-form-checkbox
                                    :key="key"
                                    v-model="checkedMenus[key].export"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="item.review">
                                  <b-form-checkbox
                                    :key="key"
                                    v-model="checkedMenus[key].review"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="item.approval">
                                  <b-form-checkbox
                                    :key="key"
                                    v-model="checkedMenus[key].approval"
                                    name="check-button"
                                    switch
                                    inline
                                    @change="handleParentActive(key)"
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                            </tr>

                            <!-- SUB MENU -->
                            <template
                              v-for="(subMenuItem, keySub) in item.child"
                            >
                              <tr :key="`submenu-${key}${keySub}`">
                                <td class="text-center">
                                  <b-form-checkbox
                                    v-model="
                                      checkedMenus[key].child[keySub].active
                                    "
                                    :name="`submenu-ck-${keySub}`"
                                    @change="selectMenu(key, keySub, false)"
                                  ></b-form-checkbox>
                                </td>
                                <td
                                  ><span style="margin-right: 60px"></span
                                  >{{ subMenuItem.menu }}</td
                                >
                                <td class="text-center">
                                  <template v-if="subMenuItem.add">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].add
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="subMenuItem.update">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].update
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="subMenuItem.delete">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].delete
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="subMenuItem.export">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].export
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="subMenuItem.review">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].review
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="subMenuItem.approval">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="
                                        checkedMenus[key].child[keySub].approval
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      @change="
                                        handleParentActive(key, keySub, false)
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                              </tr>

                              <!-- TAB MENU / SUB CHILD -->
                              <template
                                v-for="(
                                  tabMenuItem, keyTab
                                ) in subMenuItem.child"
                              >
                                <tr :key="`keytab-${key}${keySub}${keyTab}`">
                                  <td class="text-center">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].active
                                      "
                                      :name="`tab-ck-${keySub}`"
                                      @change="
                                        selectMenu(
                                          key,
                                          keySub,
                                          false,
                                          true,
                                          keyTab
                                        )
                                      "
                                    ></b-form-checkbox>
                                  </td>
                                  <td
                                    ><span style="margin-right: 100px"></span
                                    >{{ tabMenuItem.menu }}</td
                                  >
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.add">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].add
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.update">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].update
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.delete">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].delete
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.export">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].export
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.review">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].review
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="tabMenuItem.approval">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[
                                            keyTab
                                          ].approval
                                        "
                                        name="check-button"
                                        switch
                                        inline
                                        @change="
                                          handleParentActive(
                                            key,
                                            keySub,
                                            false,
                                            true,
                                            keyTab
                                          )
                                        "
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                </tr>
                              </template>
                            </template>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div v-else class="text-center my-5">
                      <p class="mb-0 text-muted"
                        >No privilege data found on selected role.</p
                      >
                    </div>
                  </template>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mb-4">
                <b-col md="6">
                  <h5 class="mb-3">Fleet Responsible</h5>
                </b-col>
                <b-col md="4" class="text-right">
                  <b-form-input
                    type="text"
                    placeholder="Search fleets..."
                    v-model="fleet_params.search"
                    @input="fetchFleets"
                  ></b-form-input>
                </b-col>
                <b-col md="2" class="text-right">
                  <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    v-model="select_all_fleets"
                  >
                    Select All
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <div v-if="loading_fleet" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div>
              <template v-else>
                <small class="text-muted"
                  >click images for checked / unchecked</small
                >
                <b-row v-if="fleets.length > 0">
                  <b-col
                    md="3"
                    class="text-center py-5 border-top"
                    v-for="(fleet, i) in fleets"
                    :key="`fleet-${i}`"
                  >
                    <div class="custom-control custom-checkbox image-checkbox">
                      <input
                        :id="`ck-fl-${i}`"
                        type="checkbox"
                        class="custom-control-input"
                        :value="fleet.id"
                        v-model="checkedFleets"
                      />
                      <label class="custom-control-label" :for="`ck-fl-${i}`">
                        <img
                          :src="
                            fleet.vehicleType.icon.url
                              | checkPhoto(
                                require('@src/assets/images/fleet/PusherBarge.png')
                              )
                          "
                          alt="#"
                          class="img-fluid"
                        />
                      </label>
                      <small
                        ><strong>{{ fleet.name }}</strong></small
                      >
                    </div>
                  </b-col>
                </b-row>
                <div v-else class="text-center my-5">
                  <p class="mb-0 text-muted">No fleets data found.</p>
                </div>
              </template>

              <div class="text-right">
                <router-link :to="{ name: 'setting.users', query: { menuObject: $route.query.menuObject } }">
                  <b-button variant="light" class="mb-3 mr-2">Cancel</b-button>
                </router-link>
                <b-button variant="primary" class="mb-3" @click="doRegister">
                  <template v-if="!loading"> Register User </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Loading...</span>
                  </template>
                </b-button>
                <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import _ from 'lodash'
import Compress from 'compress.js'
import {
  rolesActions,
  usersActions,
  companiesActions,
  menusActions,
  fleetsActions,
  filesActions,
  miActions
} from '@src/Utils/helper'

export default {
  name: 'AddUser',
  data() {
    return {
      genders: [
        { value: 'MALE', text: 'Male' },
        { value: 'FEMALE', text: 'Female' },
      ],
      user: {
        username: '',
        email: '',
        password: '',
        accessWeb: true,
        accessApp: true,
        name: '',
        phone: '',
        gender: '',
        birthday: '',
        roleId: 1,
        address: '',
        photo: null,
        companyId: 1,
        departmentId: 1,
        vehicles: [],
        menus: [],
      },
      companies: [],
      departments: [],
      roles: [],
      fleets: [],
      menus: [],
      checkedFleets: [],
      checkedMenus: [],
      loading: false,
      loading_menu: false,
      loading_fleet: false,
      loading_upload: false,
      select_all_fleets: false,
      fleet_params: {
        page: 1,
        showAll: true,
        active: true,
        search: '',
      },
      confirm_password: '',
      uploaded_photo: {},
    }
  },
  watch: {
    select_all_fleets: {
      deep: true,
      handler: function (checked) {
        this.checkedFleets = []

        if (checked) {
          this.fleets.forEach((f) => this.checkedFleets.push(f.id))
        }
      },
    },
  },
  created () {
    this.fetchFleets = _.debounce(this.fetchFleets, 1000)
  },
  mounted () {
    this.fetchRoles()
    this.fetchCompanies()
    // this.fetchDepartments()
    this.fetchMenus()
    this.fetchFleets()
  },
  methods: {
    ...rolesActions,
    ...usersActions,
    ...companiesActions,
    ...menusActions,
    ...fleetsActions,
    ...filesActions,
    ...miActions,
    async fetchRoles () {
      let res = await this.getRoles()
      this.roles = res.data
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data
    },
    async fetchDepartments () {
      let res = await this.getDepartments()
      this.departments = res.data
      /* const { data } = await this.getDepartments()
      if (data.length) {
        this.notifOptions = data.map(val => ({ id: val.id, name: val.name }))
      } */
    },
    async fetchFleets () {
      this.loading_fleet = true
      let params = this.$options.filters.cleanObject(this.fleet_params)
      let res = await this.getFleetActivities(params)

      if (!res.status) {
        this.loading_fleet = false
        this.fleets = []
      } else {
        this.loading_fleet = false
        this.fleets = res.data
      }
    },
    async fetchMenus () {
      this.loading_menu = true
      let res = await this.getMenus()
      let res_role = await this.getMenusByRole(this.user.roleId)

      this.checkedMenus = []

      if (res.status == 'error') {
        this.loading_menu = false
        this.menus = []
      } else {
        this.loading_menu = false
        let menus = res.data
        let checked_menus = res_role.data

        if (res_role.status == 'error') checked_menus = []

        this.menus = menus
        this.checkedMenus = this.mergeMenus(menus, checked_menus)
      }
    },
    async uploadPhoto (photo) {
      let formData = new FormData()
      formData.append('file', photo)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload = false
        this.uploaded_photo = res.data
        this.user.photo = res.data.url
      } else {
        this.loading_upload = false
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async doRegister () {
      let data = {
        username: this.user.username,
        email: this.user.email,
        password: this.user.password,
        accessWeb: true,
        accessApp: true,
        name: this.user.name,
        phone: this.user.phone,
        gender: this.user.gender,
        birthday: this.$options.filters.parseDate(
          this.user.birthday,
          'DD-MM-YYYY'
        ),
        roleId: this.user.roleId,
        address: this.user.address,
        photo: this.uploaded_photo.fileName,
        companyId: this.user.companyId,
        departmentId: this.user.departmentId,
        vehicles: this.checkedFleets,
        menus: [],
      }

      this.checkedMenus.forEach((menu) => {
        if (menu.active) {
          data.menus.push({
            menuId: menu.menuId,
            active: menu.active,
            add: menu.add,
            update: menu.update,
            delete: menu.delete,
            export: menu.export,
            review: menu.review,
            approval: menu.approval,
          })

          menu.child.forEach((sub_menu) => {
            if (sub_menu.active) {
              data.menus.push({
                menuId: sub_menu.menuId,
                active: sub_menu.active,
                add: sub_menu.add,
                update: sub_menu.update,
                delete: sub_menu.delete,
                export: sub_menu.export,
                review: sub_menu.review,
                approval: sub_menu.approval,
              })

              sub_menu.child.forEach((tab) => {
                if (tab.active) {
                  data.menus.push({
                    menuId: tab.menuId,
                    active: tab.active,
                    add: tab.add,
                    update: tab.update,
                    delete: tab.delete,
                    export: tab.export,
                    review: tab.review,
                    approval: tab.approval,
                  })
                }
              })
            }
          })
        }
      })

      let form_has_empty_value = false
      for (var key in data) {
        if (data[key] === '') form_has_empty_value = true
        else if (Array.isArray(data[key]) && !data[key].length)
          form_has_empty_value = true
      }

      if (form_has_empty_value)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else if (data.password !== this.confirm_password)
        this.$swal(
          "Password confirmation doesn't match!",
          'Please check again and retype password correctly.',
          'error'
        )
      else {
        let res = await this.registerUser(data)
        if (res.status == 'success') {
          this.loading = false
          this.$swal('User added!', 'User registered successfully.', 'success')
          this.$router.push({ name: 'setting.users', query: { menuObject: this.$route.query.menuObject } })
        } else {
          this.loading = false
          if (res.data.message != null)
            this.$swal('Error', res.data.message, 'error')
          else
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
        }
      }
    },
    selectMenu (
      parent_index,
      child_index = null,
      parent = true,
      tab = false,
      tab_index = null
    ) {
      let menu_to_reset = this.checkedMenus[parent_index]

      if (!parent) {
        menu_to_reset = this.checkedMenus[parent_index].child[child_index]
      }

      if (tab) {
        menu_to_reset =
          this.checkedMenus[parent_index].child[child_index].child[tab_index]
      }

      // IF USER UNSELECT THE MENU
      if (!menu_to_reset.active) {
        // OF COURSE we want to make unselected menu marked as not selected
        menu_to_reset.add = false
        menu_to_reset.delete = false
        menu_to_reset.update = false
        menu_to_reset.review = false
        menu_to_reset.approval = false
        menu_to_reset.export = false

        // IF USER UNSELECT PARENT/MENU > we want to make sure
        // it's child (submenu & tabmenu) also unselected
        if (parent && !tab) {
          if (this.checkedMenus[parent_index].child.length > 0) {
            this.checkedMenus[parent_index].child.forEach((sub_menu) => {
              sub_menu.active = false
              sub_menu.add = false
              sub_menu.delete = false
              sub_menu.update = false
              sub_menu.review = false
              sub_menu.approval = false
              sub_menu.export = false

              if (sub_menu.child.length > 0) {
                sub_menu.child.forEach((tab_menu) => {
                  tab_menu.active = false
                  tab_menu.add = false
                  tab_menu.delete = false
                  tab_menu.update = false
                  tab_menu.review = false
                  tab_menu.approval = false
                  tab_menu.export = false
                })
              }
            })
          }
        }

        // IF USER UNSELECT SUBMENU > we want to make sure it's child (tabmenu) also unselected
        if (!parent && !tab) {
          if (
            this.checkedMenus[parent_index].child[child_index].child.length > 0
          ) {
            this.checkedMenus[parent_index].child[child_index].child.forEach(
              (tab_menu) => {
                tab_menu.active = false
                tab_menu.add = false
                tab_menu.delete = false
                tab_menu.update = false
                tab_menu.review = false
                tab_menu.approval = false
                tab_menu.export = false
              }
            )
          }
        }

        // IF USER SELECT THE MENU
      } else {
        // IF SUBMENU SELECTED > we want also to make parent/menu active
        if (!parent && !tab) {
          this.checkedMenus[parent_index].active = true
        }

        // IF TABMENU SELECTED > we want also to make parent/menu & submenu active
        if (!parent && tab) {
          this.checkedMenus[parent_index].active = true
          this.checkedMenus[parent_index].child[child_index].active = true
        }

        // OF COURSE we want to make selected menu marked as active
        menu_to_reset.active = true
        menu_to_reset.add = true
        menu_to_reset.delete = true
        menu_to_reset.update = true
        menu_to_reset.review = true
        menu_to_reset.approval = true
        menu_to_reset.export = true
      }
    },
    handleParentActive (
      parent_index,
      child_index = null,
      parent = true,
      tab = false,
      tab_index = null
    ) {
      if (parent) this.checkedMenus[parent_index].active = true
      else if (tab) {
        this.checkedMenus[parent_index].active = true
        this.checkedMenus[parent_index].child[child_index].active = true
        this.checkedMenus[parent_index].child[child_index].child[
          tab_index
        ].active = true
      } else {
        this.checkedMenus[parent_index].active = true
        this.checkedMenus[parent_index].child[child_index].active = true
      }
    },
    onChangePhoto: function (event) {
      this.loading_upload = true
      const files = [...event.target.files]
      const compress = new Compress()

      const file_name = files[0].name
      const type = files[0].type

      compress
        .compress(files, {
          size: 2, // the max size in MB, defaults to 2MB
          quality: 0.5, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
        })
        .then((data) => {
          // returns an array of compressed images
          const img1 = data[0]
          const base64str = img1.data
          const imgExt = img1.ext
          const compressed_photo = Compress.convertBase64ToFile(
            base64str,
            imgExt
          )

          const reader = new FileReader()
          reader.onload = (e) => {
            this.user.photo = e.target.result
          }
          reader.readAsDataURL(compressed_photo)

          let theBlob = new File([compressed_photo], file_name, {
            lastModified: new Date().getTime(),
            type,
          })
          this.uploadPhoto(theBlob)
        })
    },
    mergeMenus (menus, checked_menus) {
      let merged_menus = []
      console.log(checked_menus)

      menus.forEach((menu) => {
        let sub_menus = []
        let found_menu = checked_menus.find((m) => m.menuId == menu.menuId)

        if (menu.child.length > 0) {
          menu.child.forEach((sub_menu) => {
            let tabs = []
            let found_sub_menu = null

            if (found_menu)
              found_sub_menu = found_menu.child.find(
                (m) => m.menuId == sub_menu.menuId
              )

            if (sub_menu.child.length > 0) {
              sub_menu.child.forEach((tab) => {
                let found_tab = []

                if (found_sub_menu)
                  found_tab = found_sub_menu.child.find(
                    (m) => m.menuId == tab.menuId
                  )

                let to_push_tabs = {
                  menuId: tab.menuId,
                  active: false,
                  add: false,
                  update: false,
                  delete: false,
                  export: false,
                  review: false,
                  approval: false,
                }
                if (found_sub_menu) {
                  if (found_tab) {
                    to_push_tabs = {
                      menuId: found_tab.menuId,
                      active: found_tab.active,
                      add: found_tab.active ? found_tab.add : false,
                      update: found_tab.active ? found_tab.update : false,
                      delete: found_tab.active ? found_tab.delete : false,
                      export: found_tab.active ? found_tab.export : false,
                      review: found_tab.active ? found_tab.review : false,
                      approval: found_tab.active ? found_tab.approval : false,
                    }
                  }
                }

                tabs.push(to_push_tabs)
              })
            }

            let to_push_sub_menu = {
              menuId: sub_menu.menuId,
              active: false,
              add: false,
              update: false,
              delete: false,
              export: false,
              review: false,
              approval: false,
              child: tabs,
            }

            if (found_menu) {
              if (found_sub_menu) {
                to_push_sub_menu = {
                  menuId: found_sub_menu.menuId,
                  active: found_sub_menu.active,
                  add: found_sub_menu.active ? found_sub_menu.add : false,
                  update: found_sub_menu.active ? found_sub_menu.update : false,
                  delete: found_sub_menu.active ? found_sub_menu.delete : false,
                  export: found_sub_menu.active ? found_sub_menu.export : false,
                  review: found_sub_menu.active ? found_sub_menu.review : false,
                  approval: found_sub_menu.active
                    ? found_sub_menu.approval
                    : false,
                  child: tabs,
                }
              }
            }

            sub_menus.push(to_push_sub_menu)
          })
        }

        let to_push = {
          menuId: menu.menuId,
          active: false,
          add: false,
          update: false,
          delete: false,
          export: false,
          review: false,
          approval: false,
          child: sub_menus,
        }

        if (found_menu) {
          to_push = {
            menuId: found_menu.menuId,
            active: found_menu.active,
            add: found_menu.active ? found_menu.add : false,
            update: found_menu.active ? found_menu.update : false,
            delete: found_menu.active ? found_menu.delete : false,
            export: found_menu.active ? found_menu.export : false,
            review: found_menu.active ? found_menu.review : false,
            approval: found_menu.active ? found_menu.approval : false,
            child: sub_menus,
          }
        }

        merged_menus.push(to_push)
      })

      return merged_menus
    },
  },
}
</script>
