var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "px-5 py-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.action === 'CREW' ? 'List Expired Crew' : 'List 1 Month Expired Crew'))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$data, _vm$data$list_crew_ex, _vm$data2, _vm$data2$list_crew_m, _vm$data3, _vm$data3$list_crew_e, _vm$data4, _vm$data4$list_crew_m;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$list_crew_ex = _vm$data.list_crew_expired) === null || _vm$data$list_crew_ex === void 0 ? void 0 : _vm$data$list_crew_ex.length) > 0 || ((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$list_crew_m = _vm$data2.list_crew_month_expired) === null || _vm$data2$list_crew_m === void 0 ? void 0 : _vm$data2$list_crew_m.length) > 0 ? _c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
        }, [_c('b-form-input', {
          staticClass: "align-self-end w-50",
          attrs: {
            "placeholder": "Search name/fleets/position..."
          },
          model: {
            value: _vm.searchQuery,
            callback: function callback($$v) {
              _vm.searchQuery = $$v;
            },
            expression: "searchQuery"
          }
        }), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.exportTable();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _c('span', [_vm._v("Export")])])])], 1) : _vm._e()]), ['CREW'].includes(_vm.action) ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$list_crew_e = _vm$data3.list_crew_expired) === null || _vm$data3$list_crew_e === void 0 ? void 0 : _vm$data3$list_crew_e.length) > 0 ? _c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Position")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Join on Board")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign On")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign Off")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.filteredList, function (crew, i) {
          var _crew$nip, _crew$vehicle$name, _crew$vehicle, _crew$company$company, _crew$company, _crew$position$name, _crew$position, _crew$status_emp_crew, _crew$status_emp_crew2, _crew$status_emp_crew3;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: crew.id
                }
              }
            }
          }, [_vm._v(_vm._s(crew.fullname))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$nip = crew === null || crew === void 0 ? void 0 : crew.nip) !== null && _crew$nip !== void 0 ? _crew$nip : '-'))])])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$vehicle$name = crew === null || crew === void 0 ? void 0 : (_crew$vehicle = crew.vehicle) === null || _crew$vehicle === void 0 ? void 0 : _crew$vehicle.name) !== null && _crew$vehicle$name !== void 0 ? _crew$vehicle$name : '-') + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v(_vm._s((_crew$company$company = crew === null || crew === void 0 ? void 0 : (_crew$company = crew.company) === null || _crew$company === void 0 ? void 0 : _crew$company.company) !== null && _crew$company$company !== void 0 ? _crew$company$company : '-'))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$position$name = crew === null || crew === void 0 ? void 0 : (_crew$position = crew.position) === null || _crew$position === void 0 ? void 0 : _crew$position.name) !== null && _crew$position$name !== void 0 ? _crew$position$name : '-') + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.join_on_board).format('DD MMMM YYYY') : '-') + " "), _vm.joinOnBoardStatus(crew) ? _c('b-badge', {
            attrs: {
              "variant": _vm.joinOnBoardStatus(crew) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(crew) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(crew) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
            }
          }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(crew)))]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_on ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_on).format('DD MMMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMMM YYYY') : '-') + " "), _c('br')]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "".concat((crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew = crew.status_emp_crewing) === null || _crew$status_emp_crew === void 0 ? void 0 : _crew$status_emp_crew.alias) === 'active' ? 'success' : 'warning', " d-block")
            }
          }, [_vm._v(_vm._s((_crew$status_emp_crew2 = crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew3 = crew.status_emp_crewing) === null || _crew$status_emp_crew3 === void 0 ? void 0 : _crew$status_emp_crew3.name) !== null && _crew$status_emp_crew2 !== void 0 ? _crew$status_emp_crew2 : '-') + " ")]), _vm.expiredContract(crew) ? _c('b-badge', {
            attrs: {
              "variant": "danger d-block"
            }
          }, [_vm._v(_vm._s(_vm.expiredContract(crew)) + " ")]) : _vm._e()], 1)]);
        }), 0)]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Expired Crew tidak ada.")])]) : _vm._e(), ['CREW_MONTH'].includes(_vm.action) ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$list_crew_m = _vm$data4.list_crew_month_expired) === null || _vm$data4$list_crew_m === void 0 ? void 0 : _vm$data4$list_crew_m.length) > 0 ? _c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Jabatan")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Join on Board")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign On")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign Off")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.filteredList, function (crew, i) {
          var _crew$nip2, _crew$vehicle$name2, _crew$vehicle2, _crew$company$company2, _crew$company2, _crew$position$name2, _crew$position2, _crew$status_emp_crew4, _crew$status_emp_crew5, _crew$status_emp_crew6;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: crew.id
                }
              }
            }
          }, [_vm._v(_vm._s(crew.fullname))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$nip2 = crew === null || crew === void 0 ? void 0 : crew.nip) !== null && _crew$nip2 !== void 0 ? _crew$nip2 : '-'))])])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$vehicle$name2 = crew === null || crew === void 0 ? void 0 : (_crew$vehicle2 = crew.vehicle) === null || _crew$vehicle2 === void 0 ? void 0 : _crew$vehicle2.name) !== null && _crew$vehicle$name2 !== void 0 ? _crew$vehicle$name2 : '-') + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v(_vm._s((_crew$company$company2 = crew === null || crew === void 0 ? void 0 : (_crew$company2 = crew.company) === null || _crew$company2 === void 0 ? void 0 : _crew$company2.company) !== null && _crew$company$company2 !== void 0 ? _crew$company$company2 : '-'))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$position$name2 = crew === null || crew === void 0 ? void 0 : (_crew$position2 = crew.position) === null || _crew$position2 === void 0 ? void 0 : _crew$position2.name) !== null && _crew$position$name2 !== void 0 ? _crew$position$name2 : '-') + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.join_on_board).format('DD MMMM YYYY') : '-') + " "), _vm.joinOnBoardStatus(crew) ? _c('b-badge', {
            attrs: {
              "variant": _vm.joinOnBoardStatus(crew) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(crew) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(crew) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
            }
          }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(crew)))]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_on ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_on).format('DD MMMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMMM YYYY') : '-') + " "), _c('br')]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "".concat((crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew4 = crew.status_emp_crewing) === null || _crew$status_emp_crew4 === void 0 ? void 0 : _crew$status_emp_crew4.alias) === 'active' ? 'success' : 'warning', " d-block")
            }
          }, [_vm._v(_vm._s((_crew$status_emp_crew5 = crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew6 = crew.status_emp_crewing) === null || _crew$status_emp_crew6 === void 0 ? void 0 : _crew$status_emp_crew6.name) !== null && _crew$status_emp_crew5 !== void 0 ? _crew$status_emp_crew5 : '-') + " ")]), _vm.expiredContract(crew) ? _c('b-badge', {
            attrs: {
              "variant": "danger d-block"
            }
          }, [_vm._v(_vm._s(_vm.expiredContract(crew)) + " ")]) : _vm._e()], 1)]);
        }), 0)]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Expired Crew 1 Month tidak ada.")])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }