var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "8",
            "md": "8"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("INPUT DAILY OPERATIONAL")])]), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "First Fleet *",
            "label-for": "first_fleet"
          }
        }, [_c('h5', [_c('strong', [_vm._v(_vm._s(!_vm.isEdit ? _vm.fleet.name : _vm.fleetEdit.name))])])])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_vm.showVoyageWidth ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Voyage with",
            "label-for": "voyage_with"
          }
        }, [_c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "default",
            "name": "color",
            "value": "UNTOWING",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Towing Free")]), _c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "primary",
            "name": "color",
            "value": "SINGLE_TOWING",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Single Towing")]), _c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "primary",
            "name": "color",
            "value": "DOUBLE_TOWING",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Double Towing")])], 1)], 1) : _vm._e(), _vm.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING' || _vm.formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('label', {
          attrs: {
            "for": "second_fleet"
          }
        }, [_vm._v("Second Fleet")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[0].breakdown,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[0], "breakdown", $$v);
            },
            expression: "formOperationalActivity.vehicles[0].breakdown"
          }
        }, [_vm._v(" Breakdown ? ")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleetListFiltered,
            "reduce": function reduce(fleetList) {
              return fleetList.id;
            },
            "disabled": _vm.loadingCheck
          },
          on: {
            "input": function input($event) {
              return _vm.handleSelectFleet(_vm.fleetList, $event, 0);
            }
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[0].vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[0], "vehicleId", $$v);
            },
            expression: "formOperationalActivity.vehicles[0].vehicleId"
          }
        })], 1)], 1)], 1) : _vm._e(), _vm.formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('label', {
          attrs: {
            "for": "another_fleet"
          }
        }, [_vm._v("Another Fleet")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[1].breakdown,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[1], "breakdown", $$v);
            },
            expression: "formOperationalActivity.vehicles[1].breakdown"
          }
        }, [_vm._v(" Breakdown ? ")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleetListFiltered,
            "reduce": function reduce(fleetList) {
              return fleetList.id;
            },
            "disabled": _vm.loadingCheck
          },
          on: {
            "input": function input($event) {
              return _vm.handleSelectFleet(_vm.fleetList, $event, 1);
            }
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[1].vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[1], "vehicleId", $$v);
            },
            expression: "formOperationalActivity.vehicles[1].vehicleId"
          }
        })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Start Date *",
            "label-for": "start_date"
          }
        }, [_c('date-picker', {
          class: {
            'border-danger': _vm.$v.formOperationalActivity.startDate.$error
          },
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "startDate", $$v);
            },
            expression: "formOperationalActivity.startDate"
          }
        }), _c('p', [_vm.$v.formOperationalActivity.startDate.$error && !_vm.$v.formOperationalActivity.startDate.required ? _c('small', {
          staticClass: "text-danger m-t-5"
        }, [_vm._v("Start Date is Required")]) : _vm._e()])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "End Date *",
            "label-for": "end_date"
          }
        }, [_c('date-picker', {
          class: {
            'border-danger': _vm.$v.formOperationalActivity.startDate.$error
          },
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time",
            "disabled": _vm.loadingCheck
          },
          model: {
            value: _vm.formOperationalActivity.endDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "endDate", $$v);
            },
            expression: "formOperationalActivity.endDate"
          }
        }), _c('p', [_vm.$v.formOperationalActivity.endDate.$error && !_vm.$v.formOperationalActivity.endDate.required ? _c('small', {
          staticClass: "text-danger m-t-5"
        }, [_vm._v("End Date is Required")]) : _vm._e()])], 1)], 1), !_vm.isEdit ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Process",
            "label-for": "process"
          }
        }, [!_vm.loadingCheck ? _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.checkVoyage.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-map-pin"
        }), _vm._v("Process")]) : _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "light"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.edit.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v("Edit")])], 1)], 1) : _vm._e()], 1), _c('hr')], 1), _vm.nextStep ? [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h6', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("PROCESS ACTIVITY")])]), _c('hr'), _c('b-form', [_c('b-form-group', {
          attrs: {
            "label": "Project *",
            "label-for": "project"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "group-values": "sub",
            "group-label": "project",
            "placeholder": "Select one",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.formOperationalActivity.subProjectId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "subProjectId", $$v);
            },
            expression: "formOperationalActivity.subProjectId"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Current Activity *",
            "label-for": "reason"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.reasonList,
            "reduce": function reduce(reasonList) {
              return reasonList.value + '|' + reasonList.text;
            },
            "placeholder": "Select Current Activity"
          },
          model: {
            value: _vm.formOperationalActivity.reasonId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "reasonId", $$v);
            },
            expression: "formOperationalActivity.reasonId"
          }
        })], 1), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          class: _vm.typeOfCargo === 'Others' ? 'col-md-6' : ''
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type *",
            "label-for": "cargo_type"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.cargoOptions,
            "reduce": function reduce(cargoOptions) {
              return cargoOptions.value;
            },
            "placeholder": "Select Type of Cargo"
          },
          model: {
            value: _vm.typeOfCargo,
            callback: function callback($$v) {
              _vm.typeOfCargo = $$v;
            },
            expression: "typeOfCargo"
          }
        })], 1)], 1), _vm.typeOfCargo === 'Others' ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Other Cargo ",
            "label-for": "other_cargo"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.typeOfCargoOther,
            callback: function callback($$v) {
              _vm.typeOfCargoOther = $$v;
            },
            expression: "typeOfCargoOther"
          }
        })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Cargo Loading *",
            "label-for": "cargo_loading"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Ton"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formOperationalActivity.cargoLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "cargoLoading", $$v);
            },
            expression: "formOperationalActivity.cargoLoading"
          }
        })], 1), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1)], 1) : _vm._e(), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Cargo Unloading *",
            "label-for": "cargo_unloading"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Ton"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formOperationalActivity.cargoUnloading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "cargoUnloading", $$v);
            },
            expression: "formOperationalActivity.cargoUnloading"
          }
        })], 1), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Is End Trip:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-checkbox', {
          key: _vm.index,
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formOperationalActivity.endOfTrip,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "endOfTrip", $$v);
            },
            expression: "formOperationalActivity.endOfTrip"
          }
        }, [_vm._v(" No / Yes ")])], 1)], 1)], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label": "Remarks",
            "label-for": "remarks"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "2"
          },
          model: {
            value: _vm.formOperationalActivity.remarks,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "remarks", $$v);
            },
            expression: "formOperationalActivity.remarks"
          }
        }), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Max 500 Character")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h6', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("POSITION ")])]), _c('hr'), _c('b-form-row', [_c('b-col', {
          class: _vm.position === 'Others Position' ? 'col-md-6' : ''
        }, [_c('b-form-group', {
          attrs: {
            "label": "Current Position *",
            "label-for": "current_position"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.text;
            },
            "placeholder": "Select Position"
          },
          model: {
            value: _vm.position,
            callback: function callback($$v) {
              _vm.position = $$v;
            },
            expression: "position"
          }
        })], 1)], 1), _vm.position === 'Others Position' ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Other Position ",
            "label-for": "other_position"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.positionOther,
            callback: function callback($$v) {
              _vm.positionOther = $$v;
            },
            expression: "positionOther"
          }
        })], 1)], 1) : _vm._e()], 1), _c('b-form-row', [_vm.showDepartureDateTime ? _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Departure",
            "label-for": "departure"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time"
          },
          model: {
            value: _vm.formOperationalActivity.departure,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "departure", $$v);
            },
            expression: "formOperationalActivity.departure"
          }
        })], 1)], 1) : _vm._e(), _vm.showArrivalDateTime ? _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "ETA / Arrival",
            "label-for": "eta"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time"
          },
          model: {
            value: _vm.formOperationalActivity.arrival,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "arrival", $$v);
            },
            expression: "formOperationalActivity.arrival"
          }
        })], 1)], 1) : _vm._e()], 1), _vm.showPortOfLoading || _vm.showPortOfDischarge ? _c('b-form-row', [_vm.showPortOfLoading ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Port of Loading",
            "label-for": "firts_fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.value;
            },
            "placeholder": "Select Port of Loading"
          },
          model: {
            value: _vm.formOperationalActivity.portLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portLoading", $$v);
            },
            expression: "formOperationalActivity.portLoading"
          }
        })], 1), _vm.formOperationalActivity.portLoading === 'Others' ? _c('b-form-group', {
          attrs: {
            "label": "Other Position "
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.portLoadingOther,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portLoadingOther", $$v);
            },
            expression: "formOperationalActivity.portLoadingOther"
          }
        })], 1) : _vm._e()], 1) : _vm._e(), _vm.showPortOfDischarge ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Port of Discharge",
            "label-for": "second_fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.value;
            },
            "placeholder": "Select Port of Discharge"
          },
          model: {
            value: _vm.formOperationalActivity.portDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portDischarge", $$v);
            },
            expression: "formOperationalActivity.portDischarge"
          }
        })], 1), _vm.formOperationalActivity.portDischarge === 'Others' ? _c('b-form-group', {
          attrs: {
            "label": "Other Position "
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.portDischargeOther,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portDischargeOther", $$v);
            },
            expression: "formOperationalActivity.portDischargeOther"
          }
        })], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.showPortAgentPol || _vm.showPortAgentPod ? _c('b-form-row', [_vm.showPortAgentPol ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Agent POL",
            "label-for": "firts_fleet"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.agentPol,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "agentPol", $$v);
            },
            expression: "formOperationalActivity.agentPol"
          }
        })], 1)], 1) : _vm._e(), _vm.showPortAgentPod ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Agent POD",
            "label-for": "second_fleet"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.agentPod,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "agentPod", $$v);
            },
            expression: "formOperationalActivity.agentPod"
          }
        })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.showLatitude || _vm.showLongtitude ? [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "primary"
          }
        }, [_c('h5', [_vm._v("GPS DMS lat long")])]), _vm.showLatitude ? [_c('b-alert', {
          staticClass: "text-center",
          attrs: {
            "show": true,
            "variant": "light"
          }
        }, [_c('strong', [_vm._v("LATITUDE DMS")])]), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Degrees *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "°"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Degrees"
          },
          model: {
            value: _vm.dmsLat.degrees,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "degrees", $$v);
            },
            expression: "dmsLat.degrees"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Minutes *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "'"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Minutes"
          },
          model: {
            value: _vm.dmsLat.minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "minutes", $$v);
            },
            expression: "dmsLat.minutes"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Seconds *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "\""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Seconds"
          },
          model: {
            value: _vm.dmsLat.seconds,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "seconds", $$v);
            },
            expression: "dmsLat.seconds"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Directions",
            "label-for": "gps"
          }
        }, [_c('b-form-select', {
          attrs: {
            "size": "md"
          },
          model: {
            value: _vm.dmsLat.directions,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "directions", $$v);
            },
            expression: "dmsLat.directions"
          }
        }, [_c('b-form-select-option', [_vm._v("Please select an option")]), _c('b-form-select-option', {
          attrs: {
            "value": "N"
          }
        }, [_vm._v("N")]), _c('b-form-select-option', {
          attrs: {
            "value": "S"
          }
        }, [_vm._v("S")])], 1)], 1)], 1)], 1)] : _vm._e(), _vm.showLongtitude ? [_c('b-alert', {
          staticClass: "text-center",
          attrs: {
            "show": true,
            "variant": "light"
          }
        }, [_c('strong', [_vm._v("LONGITUDE DMS")])]), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Degrees *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "°"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Degrees"
          },
          model: {
            value: _vm.dmsLon.degrees,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "degrees", $$v);
            },
            expression: "dmsLon.degrees"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Minutes *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "'"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Minutes"
          },
          model: {
            value: _vm.dmsLon.minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "minutes", $$v);
            },
            expression: "dmsLon.minutes"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Seconds *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "\""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Seconds"
          },
          model: {
            value: _vm.dmsLon.seconds,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "seconds", $$v);
            },
            expression: "dmsLon.seconds"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Directions",
            "label-for": "gps"
          }
        }, [_c('b-form-select', {
          attrs: {
            "size": "md"
          },
          model: {
            value: _vm.dmsLon.directions,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "directions", $$v);
            },
            expression: "dmsLon.directions"
          }
        }, [_c('b-form-select-option', [_vm._v("Please select an option")]), _c('b-form-select-option', {
          attrs: {
            "value": "E"
          }
        }, [_vm._v("E")]), _c('b-form-select-option', {
          attrs: {
            "value": "W"
          }
        }, [_vm._v("W")])], 1)], 1)], 1)], 1)] : _vm._e(), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_vm.showLatitude ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "GPS Latitude",
            "label-for": "gps"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "latitude",
            "disabled": ""
          },
          model: {
            value: _vm.formOperationalActivity.lat,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "lat", $$v);
            },
            expression: "formOperationalActivity.lat"
          }
        })], 1)], 1) : _vm._e(), _vm.showLongtitude ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "GPS Longitude",
            "label-for": "gps"
          }
        }, [_c('b-form-input', {
          staticClass: "mt-2",
          attrs: {
            "type": "number",
            "placeholder": "longitude",
            "disabled": ""
          },
          model: {
            value: _vm.formOperationalActivity.lon,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "lon", $$v);
            },
            expression: "formOperationalActivity.lon"
          }
        })], 1)], 1) : _vm._e()], 1)] : _vm._e(), _vm.showSpeed ? _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Speed *",
            "label-for": "speed"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Knot"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formOperationalActivity.speed,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "speed", $$v);
            },
            expression: "formOperationalActivity.speed"
          }
        })], 1), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1)], 1) : _vm._e(), _vm.showDocumentOnBoard ? _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Document On Board",
            "label-for": "document_on_board"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time"
          },
          model: {
            value: _vm.formOperationalActivity.docOnBoard,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "docOnBoard", $$v);
            },
            expression: "formOperationalActivity.docOnBoard"
          }
        })], 1)], 1)], 1) : _vm._e(), _vm.showPIC ? _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "PIC / PE *",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.picList,
            "reduce": function reduce(picList) {
              return picList.value;
            },
            "placeholder": "Select PIC",
            "multiple": true
          },
          model: {
            value: _vm.picSelected,
            callback: function callback($$v) {
              _vm.picSelected = $$v;
            },
            expression: "picSelected"
          }
        })], 1)], 1)], 1) : _vm._e(), _c('b-card-text', [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 2), _c('b-col', {
          staticClass: "text-right mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveActivity();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), !_vm.isEdit ? _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.initActivityForm();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")]) : _vm._e(), _vm.isEdit ? _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.cancelEdit();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Cancel Edit")]) : _vm._e()], 1)] : _vm._e()], 2)], 1), _c('b-col', {
          attrs: {
            "cols": "4",
            "md": "4"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("LAST OPERATIONAL")])]), _c('ul', {
          staticClass: "iq-timeline m-0 p-0",
          staticStyle: {
            "height": "600px"
          }
        }, _vm._l(_vm.activityList, function (each) {
          return _c('li', {
            key: each.id
          }, [_c('b-row', {
            staticClass: "m-0"
          }, [_c('b-col', {
            attrs: {
              "md": "10"
            }
          }, [_c('div', {
            staticClass: "timeline-dots-fill"
          }), _c('h6', {
            staticClass: "float-left mb-2 text-dark"
          }, [_c('i', {
            staticClass: "ri-time-fill"
          }), _vm._v(" " + _vm._s(each.startDate + ' - ' + each.endDate))]), _c('div', {
            staticClass: "d-inline-block w-100"
          }, [_c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(_vm._s(each.activityDurationFormat))]), _c('p', [_vm._v(_vm._s(each.activityName))])], 1)]), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-dropdown', {
            attrs: {
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-caret-down m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.getActivityDetail(each.id, true);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o mr-2"
          }), _vm._v("Edit")]), _c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteActivity(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete")])], 1)], 1)], 1)], 1);
        }), 0)])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }