<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-user-profile-block1">
          <template v-slot:body>
            <div class="user-details-block">
              <div class="mt-5 pt-4 pull-right">
                <b-dropdown size="sm" text="Small" variant="light" right>
                  <template slot="button-content">
                    <i class="fa fa-ellipsis-h"></i>
                  </template>
                  <b-dropdown-item
                    v-if="permissionEditFleet"
                    variant="primary"
                    @click="fetchFleetDetail(fleetData.id)"
                    ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="permissionDeleteFleet"
                    variant="danger"
                    @click="onDeleteFleet(fleetData)"
                    ><i class="fa fa-trash"></i> Delete</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="user-profile text-center">
                <img
                  v-if="
                    fleetData.img !== null ||
                    fleetData.img !== 'null' ||
                    fleetData.img !== 'undefined'
                  "
                  :src="fleetData.vehicleType.icon.url"
                  alt=""
                  class="avatar-130 img-fluid"
                />
                <img
                  v-else
                  :src="fleetData.img"
                  alt=""
                  class="avatar-130 img-fluid"
                />
                <!-- <img src="../../assets/images/page-img/tcp101.jpg" alt="profile-img" class="avatar-130 img-fluid">-->
              </div>
              <div class="text-center">
                <h4
                  ><b>{{ fleetData.name }}</b></h4
                >
                <p>{{ fleetData.company.company }}</p>
              </div>
              <ul
                class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0"
              >
                <li class="text-center">
                  <h6 class="text-primary">Year & Build</h6>
                  <h5
                    ><template
                      v-if="
                        fleetData.yearBuildCountry === null ||
                        fleetData.yearBuildCountry === 'null' ||
                        fleetData.yearBuildCountry === 'undefined'
                      "
                      >-</template
                    ><template v-else>{{
                      fleetData.yearBuildCountry
                    }}</template></h5
                  >
                </li>
                <li class="text-center">
                  <h6 class="text-primary">Flag</h6>
                  <h5
                    ><template
                      v-if="
                        fleetData.flag === null ||
                        fleetData.flag === 'null' ||
                        fleetData.flag === 'undefined'
                      "
                      >-</template
                    ><template v-else>{{ fleetData.flag }}</template></h5
                  >
                </li>
                <li class="text-center">
                  <h6 class="text-primary">Complete Date</h6>
                  <h5
                    ><template
                      v-if="
                        fleetData.deliveryDate === null ||
                        fleetData.deliveryDate === 'null' ||
                        fleetData.deliveryDate === 'undefined'
                      "
                      >-</template
                    ><template v-else>{{
                      fleetData.deliveryDate
                    }}</template></h5
                  >
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">Fleet Document</h4>
          </template>
          <template v-slot:body>
            <ul class="speciality-list m-0 p-0">
              <template v-for="(doc, index) in fleetData.docs">
                <li class="d-flex mb-4 align-items-center" :key="index">
                  <div class="user-img img-fluid"
                    ><a href="#" class="iq-bg-primary"
                      ><i class="ri-award-fill"></i></a
                  ></div>
                  <div class="media-support-info ml-3">
                    <h6>{{ doc.iconName }}Ship Particular</h6>
                    <span class="text-primary" style="cursor:pointer" @click="handleOpenFile(doc.url)">Download File</span>
                  </div>
                </li>
              </template>
            </ul>
          </template>
        </iq-card>
        <!-- <iq-card class-name="iq-card-block iq-card-stretch iq-cad-height">
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">Active Crew</h4>
          </template>
          <template v-slot:body>
            <ul class="doctors-lists m-0 p-0">
              <li
                class="d-flex mb-4 align-items-center"
                v-for="crew in crewActiveInFleet"
                :key="crew.id"
              >
                <div class="user-img img-fluid"
                  ><img
                    :src="crew?.photo ? crew?.photo : defaultPhoto"
                    alt="story-img"
                    class="rounded-circle avatar-40"
                /></div>
                <div class="media-support-info ml-3">
                  <h6>{{ crew?.fullname }}</h6>
                  <p class="mb-0 font-size-12">{{
                    crew?.position?.name ?? '-'
                  }}</p>
                </div>
                <div class="iq-card-header-toolbar d-flex align-items-center">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="p-0 text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content class="p-0">
                      <span
                        class="dropdown-toggle p-0"
                        id="dropdownMenuButton6"
                        data-toggle="dropdown"
                      >
                        <i class="ri-more-2-line"></i>
                      </span>
                    </template>
                    <b-dropdown-item
                      href="#"
                      @click="
                        $router.push({
                          path: `/crewing/profile`,
                          query: {
                            user: 'crew',
                            id: crew.id,
                          },
                        })
                      "
                      ><i class="ri-eye-line mr-2"></i>View
                      Detail</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </li>
            </ul>
            <router-link to="/ops/fleet-crew">
              <div class="btn btn-primary d-block mt-3"
                ><i class="fa fa-search"></i> VIEW CREW
                {{ fleetData.name ?? '-' }}</div
              >
            </router-link>
          </template>
        </iq-card> -->
      </b-col>

      <!-- RIGHT -->
      <b-col lg="8">
        <iq-card body-class="pb-0">
          <template v-slot:body>
            <b-row>
              <b-col md="6">
                <div class="table-responsive">
                  <table class="table table-borderless table-striped">
                    <tbody>
                      <tr>
                        <td width="35%">Code</td>
                        <td width="65%"
                          ><strong
                            ><template
                              v-if="
                                fleetData.code === null ||
                                fleetData.code === 'null' ||
                                fleetData.code === 'undefined'
                              "
                              >-</template
                            ><template v-else>{{
                              fleetData.code
                            }}</template></strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>Ownership</td>
                        <td
                          ><strong
                            ><template
                              v-if="
                                fleetData.ownership === null ||
                                fleetData.ownership === 'null' ||
                                fleetData.ownership === 'undefined'
                              "
                              >-</template
                            ><template v-else>{{
                              fleetData.ownership
                            }}</template></strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>Technical Module</td>
                        <td
                          ><strong
                            ><template v-if="fleetData.technical === true"
                              >Yes</template
                            ><template v-else>No</template></strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>ID GPS</td>
                        <td
                          ><strong
                            ><template
                              v-if="
                                fleetData.idGps === null ||
                                fleetData.idGps === 'null' ||
                                fleetData.idGps === 'undefined'
                              "
                              >-</template
                            ><template v-else>{{
                              fleetData.idGps
                            }}</template></strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>Classification</td>
                        <td
                          ><strong
                            ><template
                              v-if="
                                fleetData.classification === null ||
                                fleetData.classification === 'null' ||
                                fleetData.classification === 'undefined'
                              "
                              >-</template
                            ><template v-else>{{
                              fleetData.classification
                            }}</template></strong
                          ></td
                        >
                      </tr>
                      <tr>
                        <td>Basic Function</td>
                        <td
                          ><strong
                            ><template
                              v-if="
                                fleetData.basicFunction === null ||
                                fleetData.basicFunction === 'null' ||
                                fleetData.basicFunction === 'undefined'
                              "
                              >-</template
                            ><template v-else>{{
                              fleetData.basicFunction
                            }}</template></strong
                          ></td
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col md="6">
                <nav aria-label="breadcrumb ">
                  <ol class="breadcrumb bg-primary">
                    <li
                      class="breadcrumb-item active text-white"
                      aria-current="page"
                      >Dimension</li
                    >
                  </ol>
                </nav>
                <table class="table mb-2 table-borderless table-box-shadow">
                  <thead>
                    <tr>
                      <th width="50%">Dimensions</th>
                      <th width="25%">Feet</th>
                      <th width="25%">Meters</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><h5>Length</h5></td>
                      <td>{{ fleetData.dimension.length.feet }}</td>
                      <td>{{ fleetData.dimension.length.meter }}</td>
                    </tr>
                    <tr>
                      <td><h5>Width</h5></td>
                      <td>{{ fleetData.dimension.width.feet }}</td>
                      <td>{{ fleetData.dimension.width.meter }}</td>
                    </tr>
                    <tr>
                      <td><h5>Depth</h5></td>
                      <td>{{ fleetData.dimension.depth.feet }}</td>
                      <td>{{ fleetData.dimension.depth.meter }}</td>
                    </tr>
                    <tr>
                      <td><h5>Draft</h5></td>
                      <td>{{ fleetData.dimension.draft.feet }}</td>
                      <td>{{ fleetData.dimension.draft.meter }}</td>
                    </tr>
                    <tr>
                      <td><h5>Speed</h5></td>
                      <td colspan="2" class="text-center"
                        ><template
                          v-if="
                            fleetData.dimension.speed === null ||
                            fleetData.dimension.speed === 'null' ||
                            fleetData.dimension.speed === 'undefined'
                          "
                          >-</template
                        ><template v-else>{{
                          fleetData.dimension.speed
                        }}</template></td
                      >
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12">
                <nav aria-label="breadcrumb ">
                  <ol class="breadcrumb bg-primary">
                    <li
                      class="breadcrumb-item active text-white"
                      aria-current="page"
                      >POWER</li
                    >
                  </ol>
                </nav>
                <ol type="numbered">
                  <template v-for="(pwr, index) in fleetData.powers">
                    <li :key="index"
                      >{{ pwr.powerType.name }} - {{ pwr.powerSeries.name }}
                      <small>{{ pwr.powerSeries.model }}</small>
                      <span class="pull-right"
                        >Actual RH:
                        <strong>{{ pwr.actualRunningHours }} RH</strong></span
                      ></li
                    >
                  </template>
                </ol>
              </b-col>
              <b-col md="12">
                <nav aria-label="breadcrumb ">
                  <ol class="breadcrumb bg-primary">
                    <li
                      class="breadcrumb-item active text-white"
                      aria-current="page"
                      >ROB Type</li
                    >
                  </ol>
                </nav>
                <ol type="numbered">
                  <template v-for="(pwr, index) in fleetData.robs">
                    <li :key="index"
                      >{{ pwr.robType.name }} - {{ pwr.robSeries.name }}
                      <span class="pull-right"
                        >Current ROB:
                        <strong>{{ pwr.actualRob }} RH</strong></span
                      ></li
                    >
                  </template>
                </ol>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <b-row>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate">
              <iq-card class-name="iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>TOTAL CERTIFICATE</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{
                        statsCertificateData.totalCertificate !== null
                          ? statsCertificateData.totalCertificate
                          : '-'
                      }}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-primary">
                      <i class="las la-certificate"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate">
              <iq-card class-name="iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>EXPIRED</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{
                        statsCertificateData.totalExpired !== null
                          ? statsCertificateData.totalExpired
                          : '-'
                      }}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-danger">
                      <i class="las la-exclamation"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate">
              <iq-card class-name="iq-card iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>1 BULAN AKAN EXPIRED</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{
                        statsCertificateData.totalOneMonth !== null
                          ? statsCertificateData.totalOneMonth
                          : '-'
                      }}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-warning">
                      <i class="las la-tasks"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <iq-card class-name="iq-card-block iq-card-stretch">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="text-left">
                    <h5 class="card-title mb-2 mt-2 text-primary">
                      <strong>PROGRESS</strong>
                    </h5>
                    <h3 class="mb-0 line-height">{{
                      statsCertificateData.totalProcess !== null
                        ? statsCertificateData.totalProcess
                        : '-'
                    }}</h3>
                  </div>
                  <div class="rounded-circle iq-card-icon bg-success">
                    <i class="fa fa-clock-o"></i>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12">
        <iq-card body-class="pb-0">
          <template v-slot:body>
            <tab-nav :tabs="true" class="text-primary">
              <tab-nav-items
                :active="true"
                href="#operational"
                title="OPERATIONAL"
              />
              <tab-nav-items
                :active="false"
                href="#certificate"
                title="CERTIFICATE"
              />
              <tab-nav-items
                :active="false"
                href="#maintenance-board"
                title="MAINTENANCE BOAR"
              />
              <tab-nav-items
                :active="false"
                href="#maintenance-init"
                title="MAINTENANCE INIT"
              />
               <tab-nav-items
                :active="false"
                href="#service-repair"
                title="SERVICE & REPAIR"
              />
              <tab-nav-items
                :active="false"
                href="#list-initial"
                title="ACCIDENT"
              />
              <tab-nav-items
                :active="false"
                href="#recommendation"
                title="RECOMENDATION"
              />
              <!-- <tab-nav-items :active="false" href="#crewing" title="CREWING" /> -->
            </tab-nav>
            <div id="myTabContent" class="tab-content">
              <tab-content-item :active="true" id="operational">
                <b-row>
                  <b-col lg="12">
                    <iq-card body-class="pb-0">
                      <template v-slot:body>
                        <b-row>
                          <b-col md="8" lg="8">
                            <div
                              style="
                                height: 100%;
                                width: 100%;
                                position: relative;
                                margin-left: -30px;
                                z-index: 9999;
                              "
                            >
                              <l-map
                                :zoom="zoom"
                                :center="center"
                                :options="mapOptions"
                                style="width: 100%; height: 100%"
                                @update:center="centerUpdate"
                                @update:zoom="zoomUpdate"
                              >
                                <l-tile-layer
                                  :url="url"
                                  :attribution="attribution"
                                />
                                <l-marker
                                  :lat-lng="
                                    getLatLng(
                                      fleetData.latitude,
                                      fleetData.longitude
                                    )
                                  "
                                >
                                  <l-icon
                                    :icon-size="iconSize"
                                    :icon-anchor="iconAnchor"
                                  >
                                    <img
                                      :src="getIcon(fleetData.vehicleType.icon)"
                                      style="width: 72px"
                                    />
                                  </l-icon>

                                  <l-tooltip
                                    :options="{
                                      permanent: false,
                                      interactive: true,
                                    }"
                                    >{{ fleetData.name }}</l-tooltip
                                  >
                                </l-marker>
                                <l-control-zoom
                                  position="bottomleft"
                                ></l-control-zoom>
                              </l-map>
                            </div>
                          </b-col>
                          <b-col md="4" lg="4">
                            <iq-card
                              class-name="iq-card-block iq-card-stretch iq-cad-height"
                            >
                              <template v-slot:headerTitle>
                                <h4 class="card-title">Last Operational</h4>
                              </template>
                              <template v-slot:body>
                                <ul
                                  class="iq-timeline m-0 p-0"
                                  style="height: 340px"
                                >
                                  <li
                                    v-for="each in activityList"
                                    :key="each.id"
                                  >
                                    <b-row class="m-0">
                                      <b-col md="12">
                                        <div class="timeline-dots-fill"></div>
                                        <h6 class="float-left mb-2 text-dark"
                                          ><i class="ri-time-fill"></i>
                                          {{
                                            each.startDate +
                                            ' - ' +
                                            each.endDate
                                          }}</h6
                                        >
                                        <div class="d-inline-block w-100">
                                          <!-- <b-badge variant="danger">Market : 2 Hours | 42 Minutes</b-badge> -->
                                          <b-badge variant="info">{{
                                            each.activityDurationFormat
                                          }}</b-badge>
                                          <p>{{ each.activityName }}</p>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </li>
                                </ul>
                              </template>
                            </iq-card>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row class="mt-5" v-if="vehicleId">
                          <b-col md="12">
                            <Operational v-bind:isChildComponent="true" />
                          </b-col>
                        </b-row>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="certificate">
                <b-row>
                  <b-col lg="12">
                    <Certificate v-bind:isChildComponent="true" />
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="maintenance-board">
                <b-row>
                  <b-col lg="12">
                    <MaintenanceSummaryBoard />
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="maintenance-init">
                <b-row>
                  <b-col lg="12">
                    <MaintenanceSummaryInit />
                  </b-col>
                </b-row>
              </tab-content-item> 
              <tab-content-item :active="false" id="service-repair">
                <b-row>
                  <b-col lg="12">
                      <ServiceRepairSummary :fleetId="$route.query.vehicleId" />
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="list-initial">
                <b-row>
                  <b-col lg="12">
                    <ListInitialTable :vehicle-id="vehicleId" />
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="recommendation">
                <b-row>
                  <b-col lg="12">
                    <ListInitialRecommendation :vehicle-id="vehicleId" />
                  </b-col>
                </b-row>
              </tab-content-item>
              <!-- <tab-content-item :active="false" id="crewing">
                <div class="iq-edit-list">
                  <tab-nav :pills="true" class="iq-edit-profile d-flex">
                    <tab-nav-items
                      class="col-md-6 p-0"
                      :active="true"
                      href="#active_crew"
                      :title="`CREW ACTIVE ${fleetData.name} (CURRENT)`"
                    />
                    <tab-nav-items
                      class="col-md-6 p-0"
                      :active="false"
                      href="#history_crew"
                      :title="`HISTORY CREW ${fleetData.name}`"
                    />
                  </tab-nav>
                </div>
                <div class="iq-edit-list-data">
                  <div id="pills-tabContent-2" class="tab-content">
                    <tab-content-item :active="true" id="active_crew">
                      <b-row class="mt-5">
                        <b-col md="3">
                          <b-form-group
                            label="Jabatan"
                            label-for="clasification"
                          >
                            <b-form-select
                              plain
                              v-model="selectedJabatan"
                              :options="optionsJabatan"
                              size="sm"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select Clasification</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label="Search" label-for="search">
                            <b-form-input
                              type="text"
                              placeholder="Type Text ..."
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="1">
                          <b-form-group
                            label="Filter"
                            label-for="button_search"
                          >
                            <b-button variant="primary" class="mb-3 mr-1">
                              <i class="fa fa-filter"></i>
                            </b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col
                          sm="12"
                          md="2"
                          v-for="crew in crewActiveInFleet"
                          :key="crew.user_id"
                        >
                          <iq-card body-class="text-center">
                            <template v-slot:body>
                              <div class="doc-profile">
                                <img
                                  class="rounded-circle img-fluid avatar-80"
                                  :src="crew?.photo"
                                  alt="profile"
                                />
                              </div>
                              <div class="iq-doc-info mt-3">
                                <h4> {{ crew?.personal_data?.fullname }}</h4>
                                <p class="mb-0">{{
                                  crew?.personal_data?.seaferer_code ?? '-'
                                }}</p>
                                <p>{{ crew?.position?.name ?? '-' }}</p>
                              </div>

                              <span
                                class="btn btn-primary"
                                @click="
                                  $router.push({
                                    path: `/crewing/profile`,
                                    query: {
                                      user: 'crew',
                                      id: crew.id,
                                    },
                                  })
                                "
                                >View Profile</span
                              >
                            </template>
                          </iq-card>
                        </b-col>
                      </b-row>
                    </tab-content-item>
                    <tab-content-item :active="false" id="history_crew">
                      <b-row class="mt-5">
                        <b-col md="3">
                          <b-form-group
                            label="Jabatan"
                            label-for="clasification"
                          >
                            <b-form-select
                              plain
                              v-model="selectedJabatan"
                              :options="optionsJabatan"
                              size="sm"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select Clasification</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="3">
                          <b-form-group label="Search" label-for="search">
                            <b-form-input
                              type="text"
                              placeholder="Type Text ..."
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="1">
                          <b-form-group
                            label="Filter"
                            label-for="button_search"
                          >
                            <b-button variant="primary" class="mb-3 mr-1">
                              <i class="fa fa-filter"></i>
                            </b-button>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col
                          sm="12"
                          md="2"
                          v-for="crew in crewHistoryInFleet"
                          :key="crew.user_id"
                        >
                          <iq-card body-class="text-center">
                            <template v-slot:body>
                              <div class="doc-profile">
                                <img
                                  class="rounded-circle img-fluid avatar-80"
                                  :src="crew?.photo"
                                  alt="profile"
                                />
                              </div>
                              <div class="iq-doc-info mt-3">
                                <h4> {{ crew?.fullname }}</h4>
                                <p class="mb-0">{{
                                  crew?.personal_data?.seaferer_code ?? '-'
                                }}</p>
                                <p>{{ crew?.position?.name ?? '-' }}</p>
                              </div>

                              <span
                                class="btn btn-primary"
                                @click="
                                  $router.push({
                                    path: `/crewing/profile`,
                                    query: {
                                      user: 'crew',
                                      id: crew.id,
                                    },
                                  })
                                "
                                >View Profile</span
                              >
                            </template>
                          </iq-card>
                        </b-col>
                      </b-row>
                    </tab-content-item>
                  </div>
                </div>
              </tab-content-item> -->
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal
      id="edit-fleet"
      size="xl"
      :no-close-on-backdrop="true"
      :header-bg-variant="modal.headerBgVariant"
      :header-text-variant="modal.headerTextVariant"
      :body-bg-variant="modal.bodyBgVariant"
      :body-text-variant="modal.bodyTextVariant"
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <h5>Edit Your Fleet</h5>
        <!-- Emulate built in modal header close button action -->
        <b-button size="md" variant="outline-danger" @click="close">
          <i class="fa fa-times"></i> Close
        </b-button>
      </template>
      <AddFleet
        v-bind:formData="fleetDetail"
        @childAction="$bvModal.hide('edit-fleet')"
      />
    </b-modal>
    <!-- End Edit Fleet Form -->
  </b-container>
</template>
<script>
import { latLng, icon } from 'leaflet'
import IqCard from '../../components/xray/cards/iq-card'
import AddFleet from '../MasterPages/AddFleet.vue'
import Operational from './Operational.vue'
import Certificate from '../Certificate/Certificate.vue'
import { xray } from '../../config/pluginInit'
import {
  fleetsActions,
  dashboardActions,
  voyagesActions,
} from '@src/Utils/helper'
import moment from 'moment'
import MaintenanceSummaryBoard from '@src/views/Maintenance/MaintenanceSummaryBoard'
import MaintenanceSummaryInit from '@src/views/Maintenance/MaintenanceSummaryInit'
import ServiceRepairSummary from '@src/views/Sr/ServiceRepairSummary'
import ListInitialTable from '@src/views/MI/ListInitialTable'
import ListInitialRecommendation from '@src/views/MI/ListInitialRecommendation'

export default {
  name: 'FleetInformation',
  components: {
    ListInitialRecommendation,
    ListInitialTable,
    MaintenanceSummaryInit,
    MaintenanceSummaryBoard,
    ServiceRepairSummary,
    IqCard,
    AddFleet,
    Operational,
    Certificate,
  },
  data() {
    return {
      activityList: [],
      crewActiveInFleet: [],
      crewHistoryInFleet: [],
      defaultPhoto: require('../../assets/images/user/11.png'),
      // MAPS
      zoom: 7,
      center: latLng(-2.548926, 118.0148634),
      url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=T7IfHizkxdz98X76UCBi',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(-2.548926, 118.0148634),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      showMap: true,
      withPopup: latLng(-2.548926, 118.0148634),
      icon: icon({
        iconUrl:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Tugboat_diagram-en.svg/2560px-Tugboat_diagram-en.svg.png',
        iconSize: [62, 67],
        iconAnchor: [16, 37],
      }),
      iconSize: [62, 67],
      iconAnchor: [16, 37],
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
        },
        monthBeforeYear: false,
      },
      dateRange: [new Date(2021, 5, 1), new Date(2021, 5, 30)],
      // END MAPS DATA
      // API DATA
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark',
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark',
      },
      loading_voyage_history: false,
      loading_voyage_matrix: false,
      historiesParamsDate: [new Date(2020, 1, 1), new Date()],
      voyageHistories: [],
      voyageMatrix: [],

      fleetData: {
        latitude: '',
        longitude: '',
        company: {},
        vehicleType: {
          icon: {},
        },
        dimension: {
          length: {},
          width: {},
          depth: {},
          draft: {},
        },
        docs: [],
        powers: [],
        robs: [],
      },
      sclDateFilter: {
        startDate: null,
        endDate: null,
      },
      sclData: [],
      fleetDetail: null,
      loadingForm: false,
      statsCertificateData: {
        totalCertificate: null,
        totalExpired: null,
        totalOneMonth: null,
        totalProcess: null,
      },

      selectedJabatan: 1,
      optionsJabatan: [
        { value: '1', text: 'Master' },
        { value: '2', text: 'Chief Officer' },
        { value: '3', text: 'Second Officer' },
        { value: '4', text: 'Chief Engineer' },
        { value: '5', text: 'Second Engineer' },
        { value: '6', text: 'Third Engineer' },
        { value: '7', text: 'Oiler' },
        { value: '8', text: 'Cooker' },
      ],
      patientChart: {
        series: [
          {
            name: 'Reason Day',
            data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 15, 14, 41],
          },
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          height: 300,
          type: 'bar',
        },
        colors: ['#089bab'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            'Run',
            'Tech',
            'Dock',
            'Weather',
            'Ops',
            'Waiting Loading',
            'Waiting Unloading',
            'Doc POL',
            'Doc POD',
            'Crewing',
            'Finance',
            'Loading',
            'Unloading',
          ],
          tickPlacement: 'on',
        },
      },
      DockingChart: {
        series: [
          {
            name: 'Reason Day',
            data: [
              44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 15, 14, 41, 78, 68, 74,
              15, 65,
            ],
          },
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          // height: 275,
          type: 'bar',
        },
        colors: ['#089bab'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            'Blasting / Painting',
            'Carpentry',
            'Cleaning',
            'Docking / Undocking',
            'Electrical',
            'Facility',
            'General Service',
            'Outfitting',
            'Navigation / Radio',
            'Piping',
            'Propulsion System',
            'Steel Work',
            'Mechanical (AE 1)',
            'Mechanical (ME 1)',
            'Mechanical (ME 2)',
            'Mechanical (AE 2)',
            'Gearbox 1',
            'Gearbox 2',
          ],
          tickPlacement: 'on',
        },
      },
      MaintenanceChart: {
        chart: {
          height: 265,
          type: 'bar',
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: 'Total Maintenance',
            data: [
              50, 78, 21, 47, 15, 45, 73, 81, 57, 94, 11, 47, 66, 87, 72, 150,
              43, 65,
            ],
          },
        ],
        xaxis: {
          categories: [
            'Accomodation',
            'Hull & Load Line',
            'Tank',
            'Mooring & Anchor Equipment',
            'Fire Fighter Appliance',
            'Life Saving Appliance',
            'Navigation',
            'Radio Equipment',
            'Main Engine',
            'Aux Engine',
            'Gearbox',
            'Engine & Other Machinery',
            'Propulsion & Rudder System',
            'Electrical & Component Part',
            'Anchor Machinery',
            'Cargo Engine',
            'Cargo Pump',
            'Navigation Lamp',
          ],
        },
      },
      MaintenanceCost: {
        chart: {
          height: 261,
          type: 'bar',
        },
        colors: ['#F9A264'],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: 'Total Cost',
            data: [
              9016096, 3468668, 4091132, 601386, 9209250, 4751063, 9564460,
              857012, 5766106, 7471733, 5904718, 8644773, 4091123, 5000960,
              9446271, 3221431, 5105702, 7036968,
            ],
          },
        ],
        xaxis: {
          categories: [
            'Accomodation',
            'Hull & Load Line',
            'Tank',
            'Mooring & Anchor Equipment',
            'Fire Fighter Appliance',
            'Life Saving Appliance',
            'Navigation',
            'Radio Equipment',
            'Main Engine',
            'Aux Engine',
            'Gearbox',
            'Engine & Other Machinery',
            'Propulsion & Rudder System',
            'Electrical & Component Part',
            'Anchor Machinery',
            'Cargo Engine',
            'Cargo Pump',
            'Navigation Lamp',
          ],
        },
      },
      ServiceRepair: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Welding', 'Mechanical', 'Electrical', 'General Service'],
        series: [44, 55, 13, 43],
        colors: ['#0C9BAB', '#F9A264', '#e64141', '#ffd400'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
      radialChat: {
        type: 'radial-pie',
        height: 280,
        data: [
          {
            country: 'Minor',
            value: 401,
          },
          {
            country: 'Medium',
            value: 300,
          },
          {
            country: 'Major',
            value: 200,
          },
        ],
      },
      info: {
        cases: 0,
        recovered: 0,
        deaths: 0,
      },
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
        height: 500,
      },
      events: [
        {
          title: 'Docking Intermediate',
          start: '2021-04-08',
          color: '#fc9919',
        },
        {
          title: 'Long Event',
          start: '2019-12-07',
          end: '2019-12-10',
          color: '#ffc107', // override!
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2021-04-09T16:00:00',
          color: '#17a2b8',
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2021-04-10T16:00:00',
          color: '#17a2b8',
        },
        {
          title: 'Conference',
          start: '2021-04-15',
          end: '2021-04-16',
          color: '#27e3f4', // override!
        },
        {
          title: 'Meeting',
          start: '2021-04-25T10:30:00',
          end: '2021-04-26T12:30:00',
          color: '#0084ff',
        },
        {
          title: 'Lunch',
          start: '2021-04-28T12:00:00',
          color: '#777D74',
        },
      ],
    }
  },
  computed: {
    permissionEditFleet(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(obj => obj.menu === 'Fleets').update
      return hasAccess
    },
    permissionDeleteFleet(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(obj => obj.menu === 'Fleets').delete
      return hasAccess
    },
    vehicleId() {
      const { vehicleId } = this.$route.query

      if (!vehicleId) {
        return null
      }

      return Number(vehicleId)
    },
  },
  async mounted() {
    xray.index()
    await this.fetchFleet()
    await this.fetchStatsCertificate()
    await this.getLastActivity()
    // await this.fetchCrewInFleet()
  },
  methods: {
    ...voyagesActions,
    ...fleetsActions,
    ...dashboardActions,
    handleOpenFile(url){
      window.open(url)
    },
    async fetchFleet() {
      const res = await this.getFleetById(this.vehicleId)

      if (res.status == 'success') {
        this.fleetData = res.data
        console.log('fleet', this.fleetData)
      } else {
        this.fleetData = {}
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async fetchFleetDetail(id) {
      const res = await this.getFleetById(id)

      if (res.status == 'success') {
        this.fleetDetail = res.data
        this.$bvModal.show('edit-fleet')
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
      this.loadingForm = false
    },
    async fetchCrewInFleet() {
      if (!this.$route.query.vehicleId)
        return console.log('THIS VEHICLE DOESNT EXIST')

      const res = await this.getCrewInFleet({
        vehicle_id: this.$route.query.vehicleId,
      })

      if (res.status) {
        res.data.map((val) => {
          if (val.status_emp_crewing.name === 'Active') {
            this.crewActiveInFleet.push({
              ...val,
              photo:
                !val?.photo && val.personal_data.gender === 'Male'
                  ? require('../../assets/images/user/09.jpg')
                  : require('../../assets/images/user/11.png'),
            })
          } else {
            this.crewHistoryInFleet.push({
              ...val,
              photo:
                !val?.photo && val.personal_data.gender === 'Male'
                  ? require('../../assets/images/user/09.jpg')
                  : require('../../assets/images/user/11.png'),
            })
          }
        })
      }
    },
    async onDeleteFleet(dataVal) {
      let data = dataVal

      this.$swal({
        title: 'Delete Fleet?',
        text: `Fleet ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteFleet(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.$swal(
              `Fleet deleted!`,
              `Fleet ${data.name} successfully deleted`,
              'success'
            )
            this.$router.push({ name: 'dashboard.pms' })
          }
        }
      })
    },
    async fetchStatsCertificate() {
      let param = {
        vehicleId: this.vehicleId,
      }
      let res = await this.getStatsCertificate(param)
      if (res.data) {
        const data = res.data
        this.statsCertificateData.totalCertificate = data.totalCertificate
        this.statsCertificateData.totalExpired = data.totalExpired
        this.statsCertificateData.totalOneMonth = data.totalOneMonth
        this.statsCertificateData.totalProcess = data.totalProcess
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    getLatLng(lat, lng) {
      return latLng(lat, lng)
    },
    getIcon(icon) {
      if (!icon || !icon.url) {
        return 'fleet/PusherTug.png'
      }

      return this.$options.filters.checkPhoto(icon.url, 'fleet/PusherTug.png')
    },
    async getLastActivity() {
      this.activityList = []
      const vehicleId = this.$route.query.vehicleId
      let params = {
        page: 1,
      }
      const payload = {
        vehicleId: vehicleId,
        params,
      }
      const res = await this.getVoyageLastActivity(payload)
      if (res.status == 'success') {
        if (res.data && res.data.length > 0) {
          this.activityList = res.data
        }
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
  },
}
</script>
