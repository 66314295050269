<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>{{ action === 'COC' ? 'List Expired COC' : 'List Expired COP' }}</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <div  v-if="data?.coc_list_expired?.length > 0 || data?.cop_list_expired?.length > 0" class="d-flex w-100 align-items-center justify-content-between mb-4">
                    <b-form-input class="align-self-end w-50 " v-model="searchQuery" placeholder="Search name/nip/certificate..."></b-form-input>
                    <b-button variant="primary" @click="exportTable()">
                      <div class="d-flex gap-2 align-items-center"> 
                        <i class="fa fa-file-excel"></i> 
                        <span>Export</span>
                      </div>
                    </b-button>
                  </div>
                </b-col>
                <b-col md="12" v-if="['COC'].includes(action)">
                  <table class="table table-bordered" v-if="data?.coc_list_expired?.length > 0">
                    <thead>
                      <tr>
                        <th class=" text-center">NO</th>
                        <th class=" text-center">Name</th>
                        <th class="text-left">Certificate</th>
                        <th class="text-center">No.&nbsp;Certificate</th>
                        <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                        <th class="text-center">Tanggal&nbsp;Berakhir</th>
                        <th class="text-center">File</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(crew, i) in filteredList" :key="'crew-' + i" >
                        <td class="text-center">{{ i + 1 }}</td>
                        <td>
                          <router-link
                            :to="{
                              path: `profile`,
                              query: {
                                user: 'crew',
                                id: crew.emp_crewing?.id,
                              },
                            }">{{ crew.emp_crewing?.fullname }}</router-link><br />
                            <small style="color: gray">NIP : <b>{{ crew?.emp_crewing?.nip ?? '-' }}</b></small>
                        </td>
                        <td class="text-center">{{ crew?.certificate?.name ?? '-' }}</td>
                        <td class="text-center">{{ crew?.document_number ?? '-' }} </td>
                        <td class="text-center">{{ crew?.place_issued + ', ' + moment(crew?.date_issued).format('DD MMMM YYYY')}}</td>
                        <td class="text-center">{{ crew?.validity_period && crew?.validity_period !== 'null' ? moment(crew.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                        <td class="text-center" v-if="crew.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(crew.file_url)"
                          ><i class="fa fa-file-alt text-primary"></i
                        ></td>
                        <td class="text-center" v-else>{{ '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p v-else class="text-center my-4">List Expired COC tidak ada.</p>
                </b-col>
                <b-col md="12" v-if="['COP'].includes(action)">
                  <table class="table table-bordered" v-if="data?.cop_list_expired?.length > 0">
                    <thead>
                      <tr>
                        <th class=" text-center">NO</th>
                        <th class=" text-center">Name</th>
                        <th class="text-left">Certificate</th>
                        <th class="text-center">No.&nbsp;Certificate</th>
                        <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                        <th class="text-center">Tanggal&nbsp;Berakhir</th>
                        <th class="text-center">File</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(crew, i) in filteredList" :key="'crew-' + i" >
                        <td class="text-center">{{ i + 1 }}</td>
                        <td>
                          <router-link
                            :to="{
                              path: `profile`,
                              query: {
                                user: 'crew',
                                id: crew.emp_crewing?.id,
                              },
                            }">{{ crew?.emp_crewing?.fullname }}</router-link><br />
                            <small style="color: gray">NIP : <b>{{ crew?.emp_crewing?.nip ?? '-' }}</b></small>
                        </td>
                        <td class="text-center">{{ crew?.certificate?.name ?? '-' }}</td>
                        <td class="text-center">{{ crew?.document_number ?? '-' }} </td>
                        <td class="text-center">{{ crew?.place_issued + ', ' + moment(crew?.date_issued).format('DD MMMM YYYY')}}</td>
                        <td class="text-center">{{ crew?.validity_period && crew?.validity_period !== 'null' ? moment(crew.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                        <td class="text-center" v-if="crew.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(crew.file_url)"
                          ><i class="fa fa-file-alt text-primary"></i
                        ></td>
                        <td class="text-center" v-else>{{ '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p v-else class="text-center my-4">List COP tidak ada.</p>
                </b-col>
              </b-row>
            </template>
          </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SlideExpiredCocCop',
  props:{
    action:{
      type:String,
      default:''
    },  
    getListExpiredCocCop:{
      type:Function,
      default:true
    },  
    data:{
      type:Object,
      default: () => {}
    }
  },
  data(){
    return {
      searchQuery:''
    }
  },
  computed: {
    filteredList() {
      const searchLower = this.searchQuery.toLowerCase();
      if(['COC'].includes(this.action)){
        return this.data?.coc_list_expired?.filter(
          (item) =>
            item?.emp_crewing?.fullname?.toLowerCase().includes(searchLower) ||
            item?.emp_crewing?.nip?.toLowerCase().includes(searchLower) ||
            item?.certificate?.name.toLowerCase().includes(searchLower) 
        );
      } else if (['COP'].includes(this.action)){
        return this.data?.cop_list_expired?.filter(
          (item) =>
            item?.emp_crewing?.fullname?.toLowerCase().includes(searchLower) ||
            item?.emp_crewing?.nip?.toLowerCase().includes(searchLower) ||
            item?.certificate?.name.toLowerCase().includes(searchLower) 
        );
      }
    },
  },
  methods:{
    handleOpenFilePreview: function (url) {
      window.open(url, "_blank");
    },
    async exportTable (){
      this.loadingExport = true 
      await this.getListExpiredCocCop(true)
      this.loadingExport = false
    }
  }
}
</script>