<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List Birthday Crew</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="primary" @click="exportTable()">
                <div class="d-flex gap-2 align-items-center"> 
                  <i class="fa fa-file-excel"></i> 
                  <span>Export</span>
                </div>
              </b-button>
            </b-col>
            <b-col md="12">
              <div class="d-flex w-100 align-items-center justify-content-between mb-4">
                <div class="d-flex align-items-center ">
                  <b-form-input class="align-self-end w-50 mr-2" v-model="searchQuery" placeholder="Search name/fleets/position..."></b-form-input>
                  <date-picker
                    v-model="dateRangeBirthday"
                    type="date"
                    range
                    placeholder="Select date range"
                    value-type="YYYY-MM-DD"
                  ></date-picker>
                </div>
                <b-button
                  @click="filterDate"
                  variant="primary"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </div>
            </b-col>
            <b-col md="12">
              <table class="table table-bordered" v-if="data?.length > 0">
                <thead>
                  <tr>
                    <th class=" text-center">NO</th>
                    <th class=" text-center">Name</th>
                    <th class=" text-center">Fleet</th>
                    <th class=" text-center">Position</th>
                    <th class=" text-center">Join on Board</th>
                    <th class=" text-center">Sign On</th>
                    <th class=" text-center">Sign Off</th>
                    <th class=" text-center">Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(crew, i) in filteredList" :key="'crew-' + i" >
                    <td class="text-center">{{ i + 1 }}</td>
                    <td>
                      <router-link
                        :to="{
                          path: `profile`,
                          query: {
                            user: 'crew',
                            id: crew.id,
                          },
                        }">{{ crew.fullname }}</router-link><br />
                        <small style="color: gray">NIP : <b>{{ crew?.nip ?? '-' }}</b></small>
                    </td>
                    <td class="text-center">{{ crew?.vehicle?.name ?? '-' }}
                      <br />
                      <small style="color: gray">{{crew?.company?.company ?? '-'}}</small>
                    </td>
                    <td class="text-center">{{ crew?.position?.name ?? '-' }} </td>
                    <td class="text-center">{{ crew?.join_on_board ? moment(crew?.join_on_board).format('DD MMMM YYYY') : '-' }}
                      <b-badge
                        :variant="
                          joinOnBoardStatus(crew) === 'More than 1 year'
                            ? 'success d-block'
                            : joinOnBoardStatus(crew) === 'More than 6 month'
                            ? 'primary d-block'
                            : joinOnBoardStatus(crew) === 'More than 3 month'
                            ? 'warning d-block'
                            : 'dark d-block'
                        "
                        v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                    </td>
                    <td class="text-center">{{ crew?.sign_on ? moment(crew?.sign_on).format('DD MMMM YYYY') : '-' }}</td>
                    <td class="text-center">{{ crew?.sign_off ? moment(crew?.sign_off).format('DD MMMM YYYY') :  '-' }} <br /> </td>
                    <td class="text-center">
                      <b-badge class="mb-1" :variant="`${crew?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                        >{{ crew?.status_emp_crewing?.name ?? '-' }}
                      </b-badge>
                      <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                        >{{ expiredContract(crew) }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-else class="text-center my-4">List Crew Birthday tidak ada.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SlideListBirthday',
  props:{  
    getListBirtdayCrew:{
      type:Function,
      default:true
    },  
    data:{
      type:Array,
      default:[]
    },
  },
  data(){
    return {
      searchQuery:'',
      dateRangeBirthday: [moment().format('YYYY-MM-DD'), moment(new Date()).add(3, 'months').format("YYYY-MM-DD")], 
    }
  },
  computed: {
    filteredList() {
      const searchLower = this.searchQuery.toLowerCase();
      return this.data?.filter(
        (item) =>
          item?.fullname?.toLowerCase().includes(searchLower) ||
          item?.position?.name.toLowerCase().includes(searchLower) ||
          item?.vehicle?.name.toLowerCase().includes(searchLower) 
      );
    },
  },
  methods:{
    joinOnBoardStatus(data) {
      if (!data.join_on_board) return ''
      if (data.working_period > 12) {
        return 'More than 1 year'
      } else if (data.working_period > 6) {
        return 'More than 6 month'
      } else if (data.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async exportTable (){
      this.loadingExport = true 
      await this.getListBirtdayCrew(true)
      this.loadingExport = false
    },
    async filterDate(){
      this.loadingExport = true 
      let start_date = this.dateRangeBirthday?.[0] ? moment(new Date(this.dateRangeBirthday[0])).format('YYYY-MM-DD') : null
      let end_date = this.dateRangeBirthday?.[1] ? moment(new Date(this.dateRangeBirthday[1])).format('YYYY-MM-DD') : null

      await this.getListBirtdayCrew(false, start_date, end_date)
      this.loadingExport = false
    },

  }
}
</script>