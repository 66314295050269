var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "px-5 py-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List of Crew PKL Not Completed")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$data, _vm$data2, _vm$data3;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.length) > 0 || ((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.length) > 0 ? _c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
        }, [_c('b-form-input', {
          staticClass: "align-self-end w-50",
          attrs: {
            "placeholder": "Search name..."
          },
          model: {
            value: _vm.searchQuery,
            callback: function callback($$v) {
              _vm.searchQuery = $$v;
            },
            expression: "searchQuery"
          }
        }), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.exportTable();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _c('span', [_vm._v("Export")])])])], 1) : _vm._e()]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.length) > 0 ? _c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Position")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Join on Board")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign On")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Sign Off")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.filteredList, function (crew, i) {
          var _crew$crewing, _crew$crewing$fullnam, _crew$crewing2, _crew$crewing$nip, _crew$crewing3, _crew$crewing$vehicle, _crew$crewing4, _crew$crewing4$vehicl, _crew$crewing$company, _crew$crewing5, _crew$crewing5$compan, _crew$crewing$positio, _crew$crewing6, _crew$crewing6$positi, _crew$crewing7, _crew$crewing8, _crew$crewing9, _crew$crewing10, _crew$crewing11, _crew$crewing11$statu, _crew$crewing$status_, _crew$crewing12, _crew$crewing12$statu;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: crew === null || crew === void 0 ? void 0 : (_crew$crewing = crew.crewing) === null || _crew$crewing === void 0 ? void 0 : _crew$crewing.id
                }
              }
            }
          }, [_vm._v(_vm._s((_crew$crewing$fullnam = crew === null || crew === void 0 ? void 0 : (_crew$crewing2 = crew.crewing) === null || _crew$crewing2 === void 0 ? void 0 : _crew$crewing2.fullname) !== null && _crew$crewing$fullnam !== void 0 ? _crew$crewing$fullnam : '-'))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$crewing$nip = crew === null || crew === void 0 ? void 0 : (_crew$crewing3 = crew.crewing) === null || _crew$crewing3 === void 0 ? void 0 : _crew$crewing3.nip) !== null && _crew$crewing$nip !== void 0 ? _crew$crewing$nip : '-'))])])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$crewing$vehicle = crew === null || crew === void 0 ? void 0 : (_crew$crewing4 = crew.crewing) === null || _crew$crewing4 === void 0 ? void 0 : (_crew$crewing4$vehicl = _crew$crewing4.vehicle) === null || _crew$crewing4$vehicl === void 0 ? void 0 : _crew$crewing4$vehicl.name) !== null && _crew$crewing$vehicle !== void 0 ? _crew$crewing$vehicle : '-') + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v(_vm._s((_crew$crewing$company = crew === null || crew === void 0 ? void 0 : (_crew$crewing5 = crew.crewing) === null || _crew$crewing5 === void 0 ? void 0 : (_crew$crewing5$compan = _crew$crewing5.company) === null || _crew$crewing5$compan === void 0 ? void 0 : _crew$crewing5$compan.company) !== null && _crew$crewing$company !== void 0 ? _crew$crewing$company : '-'))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$crewing$positio = crew === null || crew === void 0 ? void 0 : (_crew$crewing6 = crew.crewing) === null || _crew$crewing6 === void 0 ? void 0 : (_crew$crewing6$positi = _crew$crewing6.position) === null || _crew$crewing6$positi === void 0 ? void 0 : _crew$crewing6$positi.name) !== null && _crew$crewing$positio !== void 0 ? _crew$crewing$positio : '-') + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$crewing7 = crew.crewing) !== null && _crew$crewing7 !== void 0 && _crew$crewing7.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : (_crew$crewing8 = crew.crewing) === null || _crew$crewing8 === void 0 ? void 0 : _crew$crewing8.join_on_board).format('DD MMMM YYYY') : '-') + " "), _vm.joinOnBoardStatus(crew) ? _c('b-badge', {
            attrs: {
              "variant": _vm.joinOnBoardStatus(crew) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(crew) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(crew) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
            }
          }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(crew)))]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$crewing9 = crew.crewing) !== null && _crew$crewing9 !== void 0 && _crew$crewing9.sign_on ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_on).format('DD MMMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$crewing10 = crew.crewing) !== null && _crew$crewing10 !== void 0 && _crew$crewing10.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMMM YYYY') : '-') + " "), _c('br')]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "".concat((crew === null || crew === void 0 ? void 0 : (_crew$crewing11 = crew.crewing) === null || _crew$crewing11 === void 0 ? void 0 : (_crew$crewing11$statu = _crew$crewing11.status_emp_crewing) === null || _crew$crewing11$statu === void 0 ? void 0 : _crew$crewing11$statu.alias) === 'active' ? 'success' : 'warning', " d-block")
            }
          }, [_vm._v(_vm._s((_crew$crewing$status_ = crew === null || crew === void 0 ? void 0 : (_crew$crewing12 = crew.crewing) === null || _crew$crewing12 === void 0 ? void 0 : (_crew$crewing12$statu = _crew$crewing12.status_emp_crewing) === null || _crew$crewing12$statu === void 0 ? void 0 : _crew$crewing12$statu.name) !== null && _crew$crewing$status_ !== void 0 ? _crew$crewing$status_ : '-') + " ")]), _vm.expiredContract(crew) ? _c('b-badge', {
            attrs: {
              "variant": "danger d-block"
            }
          }, [_vm._v(_vm._s(_vm.expiredContract(crew)) + " ")]) : _vm._e()], 1)]);
        }), 0)]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Crew PKL Not Completed doesnt Exist.")])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }