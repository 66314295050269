<template>
  <div>
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="profile.menu" :horizontal="horizontal" :logo="logo" @toggle="sidebarMini" />
      <div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
        <!-- TOP Nav Bar -->
        <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'dashboard1.home' }" @toggle="sidebarMini" :logo="logo" :horizontal="horizontal" :items="horizontalMenu">
          <template slot="right">
            <ul class="navbar-list">
              <li>
                <a href="#" class="search-toggle d-flex align-items-center">
                  <img :src="profile.userDetail.photo | checkPhoto(require('@src/assets/images/user/01.jpg'))" class="img-fluid rounded mr-3" :alt="profile.userDetail.name">
                  <div class="caption">
                    <h6 class="mb-0 line-height">{{ profile.userDetail.name }}</h6>
                    <!-- <span class="font-size-12">{{ $t('nav.user.available') }}</span> -->
                    <span class="font-size-12">{{ profile.role.roles }}</span>
                  </div>
                </a>
                <div class="iq-sub-dropdown iq-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white line-height">Hello {{ profile.userDetail.name }}</h5>
                        <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span>
                      </div>
                      <router-link to="/me/my-profile" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ $t('nav.user.profileTitle') }}</h6>
                            <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p>
                          </div>
                        </div>
                      </router-link>
                      <router-link to="/me/update-profile" class="iq-sub-card iq-bg-primary-success-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ $t('nav.user.profileEditTitle') }}</h6>
                            <p class="mb-0 font-size-12">{{ $t('nav.user.profileEditSub') }}</p>
                          </div>
                        </div>
                      </router-link>
                      <div class="d-inline-block w-100 text-center p-3">
                        <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="doLogout" role="button">{{ $t('nav.user.signout') }}<i class="ri-login-box-line ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </NavBarStyle1>
        <!-- TOP Nav Bar END -->
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
<!--        <FooterStyle1>
          <template v-slot:left>

          </template>
          <template v-slot:right>
            Copyright 2020 <a href="#">Ops Fleet</a> All Rights Reserved.
          </template>
        </FooterStyle1>-->
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import SideBarStyle1 from '../components/xray/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/xray/navbars/NavBarStyle1'
import HorizontalItems from '../FackApi/json/HorizontalMenu'
import { Users } from '../FackApi/api/chat'
import { mapGetters, mapActions } from 'vuex'
import { xray } from '../config/pluginInit'
import { notificationsActions } from '@src/Utils/helper'

import logo from '../assets/images/logo-API.png'

export default {
  name: 'Layout1',
  components: {
    SideBarStyle1,
    NavBarStyle1
  },
  mounted () {
    this.updateRadio()
    this.fetchNotifications()
  },
  computed: {
    ...mapGetters("Auth", ["profile", "loggedIn"]),
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState'
    }),
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      horizontal: false,
      mini: false,
      darkMode: false,
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      horizontalMenu: HorizontalItems,
      usersList: Users,
      rtl: false,
      logo,
      notifications: [],
    }
  },
  created() {
    if (!this.loggedIn) {
      this.$swal("Unauthenticated", "Please re-login again.", "error");
      this.$router.push({ name: 'auth1.sign-in1' });
    }

    this.doReadNotification = _.debounce(
      this.doReadNotification,
      1000
    )
  },
  methods: {
    ...mapActions("Auth", ["logout","refreshToken"]),
    ...notificationsActions,
    updateRadio () {
      this.horizontal = this.$store.getters['Setting/horizontalMenuState']
      this.mini = this.$store.getters['Setting/miniSidebarState']

      if(this.mini) {
        document.body.classList.add('sidebar-main-menu')
      } else {
        document.body.classList.remove('sidebar-main-menu')
      }
    },
    sidebarHorizontal () {
      this.$store.dispatch('Setting/horizontalMenuAction')
      this.updateRadio()
    },
    sidebarMini () {
      xray.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      this.updateRadio()
    },
    rtlChange () {
      if (this.rtl) {
        this.rtlRemove()
      } else {
        this.rtlAdd()
      }
    },
    changeColor (code) {
      document.documentElement.style.setProperty('--iq-primary', code.primary)
      document.documentElement.style.setProperty('--iq-primary-light', code.primaryLight)
      if (this.darkMode) {
        document.documentElement.style.setProperty('--iq-bg-dark-color', code.bodyBgDark)
      } else {
        document.documentElement.style.setProperty('--iq-bg-light-color', code.bodyBgLight)
      }
    },
    reset () {
      this.changeColor({ primary: '#827af3', primaryLight: '#b47af3', bodyBgLight: '#efeefd', bodyBgDark: '#1d203f' })
      this.animated = { enter: 'zoomIn', exit: 'zoomOut' }
      this.light()
    },
    doLogout () {
      this.logout()
      this.$router.push({ name: 'auth1.sign-in1' })
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    }),
    async fetchNotifications () {
      const params = { page: 1 }

      const res = await this.getNotifications(params)

      if (res.status == 'success') {
        const { data, pagination } = res
        this.notifications = data.filter(item => !item.hasRead)
      } else if (res.status == 'unauthorized') {
        const resRefreshToken = await this.refreshToken()

        if(resRefreshToken.status === 'error') {
          this.doLogout()
        } else {
          location.reload()
        }
      } else {
        this.notifications = []
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async doReadNotification(notificationId) {
      await this.readNotification(notificationId)
      this.fetchNotifications()
    },
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>