<template>
  <div>
    <section class="sign-in-page">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
              <Slick :option="slickOptions">
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/Km3FO09.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Tug Boat Operations</h4>
                  <p>This system will help you to manage your Tug Boat Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/Barge.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom">
                    <div class="float"></div>
                  </div>
                  <h4 class="mb-1 text-white">Manage your Barge Operations</h4>
                  <p>This system will help you to manage your Tug Boat Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/fleet/PusherTug.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Pusher Tug</h4>
                  <p>This system will help you to manage your Pusher Tug Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/fleet/PusherBarge.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Pusher Barge</h4>
                  <p>This system will help you to manage your Pusher Barge Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/fleet/MotherVessel2.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Mother Vessel</h4>
                  <p>This system will help you to manage your Mother Vessel Operations.</p>
                </div>
                <div class="item">
                  <div class="top3">
                    <div class="boat2"><img :src="require('../../assets/images/fleet/FloatingCrane.gif')" alt=""></div>
                    <div class="waves2"></div>
                  </div>
                  <div class="bottom"><div class="float2"></div></div>
                  <h4 class="mb-1 text-white">Manage your Floating Crane</h4>
                  <p>This system will help you to manage your Floating Crane Operations.</p>
                </div>
                <div class="item">
                  <div class="top-static">
                    <div class="boat-static"><img :src="require('../../assets/images/fleet/HeavyEquipment.gif')" alt="" style="margin-top: -50px;"></div>
                  </div>
                  <h4 class="mb-1 text-white">Manage your Heavy Equipment</h4>
                  <p>This system will help you to manage your Heavy Equipment Operations.</p>
                </div>
              </Slick>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/logo-API.png'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  data () {
    return {
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        fade: true,
        slidesToShow: 1,
        arrows: false,
        dots: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
