import { render, staticRenderFns } from "./MasterCertificateReasons.vue?vue&type=template&id=5ca9482f&scoped=true&"
import script from "./MasterCertificateReasons.vue?vue&type=script&lang=js&"
export * from "./MasterCertificateReasons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca9482f",
  null
  
)

export default component.exports