<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>OPERATION MANAGEMENT</strong></h4
            >
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right"
              v-b-toggle.collapse-filter
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-filter" visible>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Category:" label-for="kind-fleet">
                    <template v-for="(item, index) in vehicleTypes">
                      <b-form-checkbox
                        v-model="item.checked"
                        name="check-button"
                        switch
                        inline
                        :key="index"
                      >
                        {{ item.name }}
                      </b-form-checkbox>
                    </template>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="search_vehicle" label="Search">
                    <b-form-input
                      v-model="params.search"
                      id="search_vehicle"
                      placeholder="search by name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Company">
                    <b-form-select
                      plain
                      :options="CompanyName"
                      id="gender"
                      v-model="params.companyId"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label-for="dateIssued"
                    label="Category Ownership"
                  >
                    <b-form-select
                      plain
                      :options="categoryOwnership"
                      id="gender"
                      v-model="params.ownership"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Filter" label-for="button_search">
                    <b-button
                      @click="fetchFleetActivities"
                      variant="primary"
                      class="mb-3 mr-1"
                      ><i class="fa fa-filter"></i
                    ></b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->

    <!-- Content Section !-->
    <iq-card v-if="loading">
      <template v-slot:body>
        <div class="text-center my-5 w-100">
          <b-spinner type="grow" label="Spinning"></b-spinner>
        </div>
      </template>
    </iq-card>
    <template v-else>
      <iq-card v-if="fleetActivities.length < 1">
        <template v-slot:body>
          <p class="text-center">No fleet activities found.</p>
        </template>
      </iq-card>

      <template v-else>
        <b-row>
          <b-col sm="4" v-for="(f, i) in fleetActivities" :key="`f-${i}`">
            <b-card class="iq-mb-3">
              <b-card-text>
                <div class="top d-flex justify-content-between">
                  <div v-if="f.miData" class="mb-5" style="z-index: 9">
                    <a href="#" @click.prevent="handleOpenMI(f)">
                      <h5 class="mt-1 ml-1">TOTAL CASE:</h5>
                      <p>
                        <b-button variant="outline-danger" class="m-1">
                          Claim
                          <b-badge variant="danger" class="ml-2">{{
                            f.miData.totalClaim
                          }}</b-badge>
                        </b-button>
                        <b-button variant="outline-primary" class="m-1">
                          Non Claim
                          <b-badge variant="primary" class="ml-2">{{
                            f.miData.totalNonClaim
                          }}</b-badge>
                        </b-button>
                      </p>
                    </a>
                  </div>
                  <div class="mb-5" style="z-index: 9">
                    <b-dropdown size="sm" text="Small" variant="light" right>
                      <template slot="button-content">
                        <i class="fa fa-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item
                        v-if="permissionEditFleet"
                        variant="primary"
                        @click="fetchFleetDetail(f.id)"
                        ><i class="fa fa-edit"></i> Edit</b-dropdown-item>
                      <b-dropdown-item
                        v-if="permissionDeleteFleet"
                        variant="danger"
                        @click="onDeleteFleet(f)"
                        ><i class="fa fa-trash"></i> Delete</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div
                    class="boat"
                    :class="{
                      'boat-no-animation': [7, 6].includes(f.vehicleType.id),
                    }"
                  >
                    <img
                      v-if="
                        f.vehicleType &&
                        f.vehicleType.icon &&
                        f.vehicleType.icon.url
                      "
                      :src="f.vehicleType.icon.url"
                      alt=""
                    />
                    <img
                      v-else
                      :src="require('../../assets/images/Barge.png')"
                      alt=""
                    />
                  </div>
                  <div
                    class="waves"
                    v-if="![7, 6].includes(f.vehicleType.id)"
                  ></div>
                </div>
                <div
                  class="bottom"
                  :style="{
                    background: [7, 6].includes(f.vehicleType.id)
                      ? '#d4e8ff !important'
                      : '#33ccff',
                  }"
                >
                  <div
                    class="float"
                    v-if="![7, 6].includes(f.vehicleType.id)"
                  ></div>
                </div>
              </b-card-text>
              <b-card-text
                ><router-link
                  :to="{
                    path: 'fleet-information',
                    query: {
                      vehicleId: f.id,
                      menuObject: hashObject(f.modules[2]),
                    },
                  }"
                  ><h4 class="mb-3 text-center text-danger"
                    ><b>{{ f.name }}</b></h4
                  ></router-link
                >
              </b-card-text>
              <b-card-text>
                <b-row class="text-center">
                  <template v-for="(m, im) in f.modules">
                    <b-col
                      cols="4"
                      md="3"
                      class="mb-3"
                      :key="`m-${im}`"
                      v-if="
                        m.hasAccess &&
                        (validateTechnicalPermission(m.url) ||
                          validateOperationalPermission(m.url) ||
                          validateReportPermission(m.url))
                      "
                    >
                      <router-link
                        :to="{
                          path: m.url,
                          query: { vehicleId: f.id, menuObject: hashObject(m) },
                        }"
                      >
                        <img
                          v-if="m.icon && m.icon.url"
                          :src="m.icon.url"
                          alt="Responsive image"
                          v-b-tooltip.top="m.name"
                        />
                        <img
                          v-else
                          src="../../assets/images/flaticon/operational.png"
                          alt="Responsive image"
                          v-b-tooltip.top="'Operational'"
                        />
                        <small style="font-size: 8pt !important">{{
                          m.name
                        }}</small>
                      </router-link>
                    </b-col>
                    <b-col
                      md="12"
                      :key="`m-b-${im}`"
                      v-if="m.hasAccess && false"
                    >
                      <router-link to="/ops/docking">
                        <b-button block variant="success" class="mb-3"
                          ><i class="fa fa-ship"></i>Docking Intermediate
                          Survey</b-button
                        >
                      </router-link>
                    </b-col>
                  </template>
                </b-row>

                <b-row v-if="permissionMaintenanceSetup">
                  <template>
                    <b-col md="12">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0 bg-transparent" role="tab">
                          <b-button v-b-modal.clone_component variant="success" block class="justify-content-between d-flex align-items-center border-0">
                            <span>Maintenance Setup Component</span>
                            <i class="fa fa-clone"></i>
                          </b-button>
                          <b-modal id="clone_component" size="large" title="Duplikasi Komponen Kapal" header-bg-variant="light" header-text-variant="dark" footer-bg-variant="light" footer-text-variant="dark">
                            <b-alert :show="true" variant="primary">
                              <div class="iq-alert-icon">
                                <i class="ri-alert-line"></i>
                              </div>
                              <div class="iq-alert-text"><b>Perhatikan</b> Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya</div>
                            </b-alert>
                            <b-row>
                              <b-col md="12">
                                <b-form-group label="Dari Komponen Kapal*" label-for="fleet">
                                  <b-form-select plain size="lg">
                                    <b-form-select-option>Select Fleet</b-form-select-option>
                                    <b-form-select-option>TB. TAVIA 1</b-form-select-option>
                                    <b-form-select-option>TB. TAVIA 2</b-form-select-option>
                                    <b-form-select-option>BG. LANGIT 1</b-form-select-option>
                                    <b-form-select-option>BG. LANGIT 2</b-form-select-option>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="12">
                                <b-form-group label="Ke Komponen Kapal*" label-for="fleet">
                                  <b-form-select plain size="lg">
                                    <b-form-select-option>Select Fleet</b-form-select-option>
                                    <b-form-select-option>TB. TAVIA 1</b-form-select-option>
                                    <b-form-select-option>TB. TAVIA 2</b-form-select-option>
                                    <b-form-select-option>BG. LANGIT 1</b-form-select-option>
                                    <b-form-select-option>BG. LANGIT 2</b-form-select-option>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-modal>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>

                <b-row v-if="permissionMaintenanceInitiation">
                  <template>
                    <b-col md="12">
                      <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-0 bg-transparent" role="tab">
                          <b-button v-b-modal.clone_component variant="warning" block class="justify-content-between d-flex align-items-center border-0">
                            <span>Maintenance Initiation</span>
                            <i class="fa fa-clone"></i>
                          </b-button>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>

                <b-row v-if="permissionSpecialSurvey">
                  <template>
                    <b-col md="12">
                      <b-card no-body class="mb-1">
                        <b-card-header
                          header-tag="header"
                          class="p-0 bg-transparent"
                          role="tab"
                        >
                          <b-button
                            v-if="f?.unscheduledDocking.includes('INTERMEDIATE')"
                            @click="$router.push(`/docking/dashboard?vehicleId=${f?.id}&typeSchedule=${'intermerdiate'}&menuObject=${hashObject(f?.modules[6])}`)"
                            block
                            :variant="'danger'"
                            :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                            ><span>Set intermediate Survey</span>
                            <i class="fa fa-ship "></i>
                          </b-button>
                          <b-button
                            v-if="f?.unscheduledDocking.includes('SPECIAL')"
                            @click="$router.push(`/docking/dashboard?vehicleId=${f?.id}&typeSchedule=${'special_value'}&menuObject=${hashObject(f?.modules[6])}`)"
                            block
                            :variant="'info'"
                            :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                            ><span>Set Special Survey</span>
                            <i class="fa fa-ship "></i>
                          </b-button>
                        </b-card-header>
                      </b-card>
                    </b-col>
                  </template>
                </b-row>

                 <!-- List Crew On Each Fleet -->
                <!-- <b-row>
                  <template>
                    <b-col md="12">
                      <b-card no-body class="mb-1">
                        <b-card-header
                          header-tag="header"
                          class="p-0 bg-transparent"
                          role="tab"
                        >
                          <b-button
                            block
                            variant="primary"
                            v-b-toggle="`accordion-` + i"
                            :class="`${`justify-content-between d-flex align-items-center border-0`}`"
                            ><span>List Crew ({{ f?.total_crew }})</span>
                            <i class="ri-group-fill"></i>
                          </b-button>
                        </b-card-header>
                        <b-collapse
                          :id="`accordion-` + i"
                          :visible="false"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body class="p-0 py-2">
                            <ul
                              class="doctors-lists m-0 p-0"
                              v-if="f?.crew_data?.length > 0"
                            >
                              <li
                                class="d-flex mb-4 align-items-center"
                                v-for="crew in f.crew_data"
                                :key="'crew-' + crew.id"
                              >
                                <div class="user-img img-fluid"
                                  ><img
                                    :src="crew?.photo"
                                    alt="story-img"
                                    class="rounded-circle avatar-40"
                                /></div>
                                <div class="media-support-info ml-3">
                                  <h6>{{ crew?.personal_data?.full_name ?? '-' }}</h6>
                                  <p class="mb-0 font-size-12">{{ crew?.position?.name ?? '-' }}</p>
                                  <small class="mb-0 font-size-12"> 
                                    Join On Board: <strong>{{ crew?.join_on_board ? moment(crew?.join_on_board).format('DD MMMM YYYY') : '-'}}</strong>
                                  </small>
                                  <br />
                                  <small class="mb-0 font-size-12"> 
                                    Sign Off: <strong>{{ crew?.sign_off ? moment(crew?.sign_off).format('DD MMMM YYYY') : '-'}}</strong>
                                  </small>
                                </div>
                                <div
                                  class="iq-card-header-toolbar d-flex align-items-center"
                                >
                                  <b-dropdown
                                    size="lg"
                                    variant="link"
                                    toggle-class="p-0 text-decoration-none"
                                    no-caret
                                  >
                                    <template v-slot:button-content class="p-0">
                                      <span
                                        class="dropdown-toggle p-0"
                                        id="dropdownMenuButton6"
                                        data-toggle="dropdown"
                                      >
                                        <i class="ri-more-2-line"></i>
                                      </span>
                                    </template>

                                    <b-dropdown-item
                                      href="#"
                                      @click="
                                        $router.push({
                                          path: `/crewing/profile`,
                                          query: {
                                            user: 'crew',
                                            id: crew.id,
                                          },
                                        })
                                      "
                                      ><i class="ri-eye-line mr-2"></i>View
                                      Detail</b-dropdown-item
                                    >
                                  </b-dropdown>
                                </div>
                              </li>
                            </ul>
                            <div
                              class="d-flex align-items-center justify-content-center"
                              v-else
                            >
                              <h4 class="text-muted">
                                <strong>FLEET INI TIDAK MEMILIKI CREW</strong>
                              </h4>
                            </div>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </b-col>
                  </template>
                </b-row> -->
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="auto mx-auto my-3" v-if="!isHideLoadMore">
            <b-button
              variant="dark px-4 py-3"
              @click="fetchFleetActivitiesLoadMore()"
              ><b-spinner
                v-if="isFetchingLoadMore"
                class="mr-2"
                small
                label="Loading..."
              ></b-spinner>
              load more</b-button
            >
          </b-col>
        </b-row>
        <!-- Edit Fleet Form -->
        <b-modal
          id="edit-fleet"
          size="xl"
          :no-close-on-backdrop="true"
          :header-bg-variant="modal.headerBgVariant"
          :header-text-variant="modal.headerTextVariant"
          :body-bg-variant="modal.bodyBgVariant"
          :body-text-variant="modal.bodyTextVariant"
          hide-footer
        >
          <template v-slot:modal-header="{ close }">
            <h5>Edit Your Fleet</h5>
            <!-- Emulate built in modal header close button action -->
            <b-button size="md" variant="outline-danger" @click="close">
              <i class="fa fa-times"></i> Close
            </b-button>
          </template>
          <AddFleet
            v-bind:formData="fleetDetail"
            @save="fetchFleetActivities()"
            @childAction="$bvModal.hide('edit-fleet')"
          />
        </b-modal>
        <!-- End Edit Fleet Form -->
        <!-- SIDEBAR MI -->
        <b-sidebar
          v-if="fleetDetailMiDataModal"
          id="sidebar-mi"
          :title="`${fleetDetailMiDataModal.name} - TOTAL CASE DETAIL`"
          width="1350px"
          backdrop-variant="dark"
          right
          backdrop
          shadow
        >
          <b-row class="pt-2 pl-3 pr-3 pb-2">
            <b-col md="12" class="mb-3">
              <hr />
            </b-col>
            <!-- TAB -->
            <b-col lg="12">
              <iq-card body-class="p-0">
                <template v-slot:body>
                  <div class="iq-edit-list">
                    <tab-nav :pills="true" class="iq-edit-profile d-flex">
                      <tab-nav-items
                        class="col-md-3 p-0"
                        :active="true"
                        href="#accident"
                        title="Accident / Incident"
                      />
                      <tab-nav-items
                        class="col-md-3 p-0"
                        :active="false"
                        href="#recommendation"
                        title="Recommendation"
                      />
                      <tab-nav-items
                        class="col-md-3 p-0"
                        :active="false"
                        href="#evaluation"
                        title="Evaluation"
                      />
                      <tab-nav-items
                        class="col-md-3 p-0"
                        :active="false"
                        href="#erm"
                        title="Enterprise Risk Management"
                      />
                    </tab-nav>
                  </div>
                </template>
              </iq-card>
            </b-col>
            <!-- END TAB -->
            <b-col lg="12">
              <div id="pills-tabContent-3" class="tab-content">
                <!-- Operational -->
                <tab-content-item :active="true" id="accident">
                  <SummaryManagementDetailAccidentList
                    :data="fleetDetailMiDataModal.forms"
                  />
                </tab-content-item>
                <tab-content-item :active="false" id="recommendation">
                  <SummaryManagementRecommendations
                    :lists="fleetDetailMiDataModal.recommendations"
                  />
                </tab-content-item>
                <tab-content-item :active="false" id="evaluation">
                  <SummaryManagementEvaluations
                    :lists="fleetDetailMiDataModal.evaluations"
                  />
                </tab-content-item>
                <tab-content-item :active="false" id="erm">
                  <SummaryManagementERM :lists="fleetDetailMiDataModal.erm" />
                </tab-content-item>
              </div>
            </b-col>
          </b-row>
        </b-sidebar>
        <!-- END SIDEBAR MI -->
      </template>
    </template>
    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import AddFleet from '../MasterPages/AddFleet.vue'
import { fleetsActions, companiesActions, miActions } from '@src/Utils/helper'
import SummaryManagementDetailAccidentList from '@src/views/MI/SummaryManagementDetailAccidentList'
import SummaryManagementRecommendations from '@src/views/MI/SummaryManagementRecommendations'
import SummaryManagementEvaluations from '@src/views/MI/SummaryManagementEvaluations'
import SummaryManagementERM from '@src/views/MI/SummaryManagementERM'

export default {
  name: 'PMS',
  components: {
    SummaryManagementERM,
    SummaryManagementEvaluations,
    SummaryManagementRecommendations,
    SummaryManagementDetailAccidentList,
    AddFleet,
  },
  data() {
    return {
      fleetDetail: null,
      fleetDetailMiDataModal: null,
      defaultPhoto: require('../../assets/images/user/11.png'),
      categoryOwnership: [
        { value: 'all', text: 'All Category Ownership' },
        { value: 'OWNED', text: 'Owned By Company' },
        { value: 'CHARTERED', text: 'Chartered' },
      ],

      // Breadcrumb
      breadcrumIcon: [
        {
          html: '<i class="ri-home-4-line mr-1 float-left"></i>PMS',
          href: '#',
          active: true,
        },
      ],
      loading: true,
      params: {
        page: 1,
        perPage: 12,
        active: true,
        ownership: 'all',
        search: '',
        companyId: null,
        vehicleTypeId: '',
      },
      isFetchingLoadMore: false,
      isHideLoadMore: false,
      fleetActivities: [],
      crewInFleet: [],
      // Edit Fleet
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark',
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark',
      },
      fleetCategory: [
        {
          label: 'Tug Boat',
          color: 'success',
          value: 'tugboat',
        },
        {
          label: 'Barge',
          color: 'warning',
          value: 'barge',
        },
        {
          label: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel',
        },
        {
          label: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment',
        },
        {
          label: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug',
        },
        {
          label: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge',
        },
      ],
      technicalActive: {
        technical_module: 'active',
      },
      formPower: {
        _saving: false,
        typeOfPower: [
          {
            type: '',
            series: '',
            actual: '',
          },
        ],
      },
      powerOptions: [
        { value: '1', text: 'Main Engine 1' },
        { value: '2', text: 'Main Engine 1' },
        { value: '3', text: 'Auxiliary Engine 1' },
        { value: '4', text: 'Auxiliary Engine 2' },
        { value: '5', text: 'Diesel Generator 1' },
        { value: '6', text: 'Diesel Generator 2' },
        { value: '7', text: 'Diesel Generator 3' },
        { value: '8', text: 'Engine Crane 1' },
        { value: '9', text: 'Emergency Generator' },
      ],
      ROBOptions: [
        { value: '1', text: 'Fuel Oil' },
        { value: '2', text: 'Fresh Water' },
        { value: '3', text: 'Drill Water' },
        { value: '4', text: 'Barite' },
        { value: '5', text: 'Bentonite' },
        { value: '6', text: 'Cement' },
        { value: '7', text: 'Brine' },
      ],
      seriesROBOptions: [
        { value: '1', text: 'TELS 46' },
        { value: '2', text: 'TELS 48' },
        { value: '3', text: 'OMEL 100' },
        { value: '4', text: 'SHELL' },
      ],
      formROBType: {
        _saving: false,
        typeOfROB: [
          {
            type: '',
            series: '',
            actual: '',
          },
        ],
      },
      main_engine: [
        { value: 'Yanmar 12LAK-STE2', text: 'Yanmar 12LAK-STE2' },
        {
          value: 'Weichai TD226B - 3CDI/4CD',
          text: 'Weichai TD226B - 3CDI/4CD',
        },
        { value: 'Cummin s6BT5.9DM', text: 'Cummin s6BT5.9DM' },
        { value: 'MITSUBISHI S6R2-T2MTK3L', text: 'MITSUBISHI S6R2-T2MTK3L' },
        { value: 'Caterpillar C-32', text: 'Caterpillar C-32' },
      ],
      CompanyName: [{ value: null, text: 'All Company' }],
      selectedCertificateName: null,
      certificateName: [
        { value: '1', text: 'SNPP/IOPP/Marpol' },
        { value: '2', text: 'Class Mesin' },
        { value: '3', text: 'Class Garis Muat' },
        { value: '4', text: 'Class Lambung' },
        { value: '5', text: 'Sertifikat Keselamatan Radio Kapal Barang' },
        { value: '6', text: 'Surat Laut' },
        { value: '7', text: 'Sertifikat Safe Manning' },
      ],
      selectedCertificateType: null,
      certificateType: [
        { value: '1', text: 'Permanent-Endorsement' },
        { value: '2', text: 'Unpermanent-Endrsement' },
      ],
      Contract: [
        { value: '1', text: 'Owned by Company' },
        { value: '2', text: 'Chartered' },
      ],
      job_check: [
        {
          checked: false,
          disabled: false,
        },
      ],
      formFileSupportList: {
        _saving: false,
        fileSupport: [
          {
            name: '',
            file: '',
          },
        ],
      },
      technical: [
        {
          name: 'technical_module',
          label: 'Yes',
          value: 'active',
          disabled: false,
        },
        {
          name: 'technical_module',
          label: 'No',
          value: 'inactive',
          disabled: false,
        },
      ],
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    permissionMaintenanceSetup(){
      let active = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Setup Component').active

      let add = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Setup Component').add

      let hasAccess = false
      if(active && add){
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionMaintenanceInitiation(){
      let active = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Master Maintenance')?.active

      let add = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Master Maintenance')?.add

      let hasAccess = false
      if(active && add){
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionSpecialSurvey(){
      let active = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Docking')?.active

      let add = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps')?.child
        .find(obj => obj.menu === 'Technical')?.child
        .find(obj => obj.menu === 'Docking')?.add

      let hasAccess = false
      if(active && add){
        hasAccess = true
      } else {
        hasAccess = false
      }
      return hasAccess
    },
    permissionEditFleet(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(obj => obj.menu === 'Fleets').update
      return hasAccess
    },
    permissionDeleteFleet(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu.find(obj => obj.menu === 'Fleets').delete
      return hasAccess
    },
    userVehicles() {
      return this.$store.state.auth.profile.vehicle || []
    },
    vehicleTypes() {
      let vehicleTypes = []

      this.userVehicles.forEach((vehicle) => {
        const vehicleTypeExist = vehicleTypes.find(
          (v) => v.id === vehicle.vehicleType.id
        )
        if (!vehicleTypeExist) {
          vehicle.vehicleType.checked = true
          vehicleTypes.push(vehicle.vehicleType)
        }
      })

      return vehicleTypes
    },
    technicalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Technical')
    },
    operationalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Operations')
    },
    reportPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Report')
    },
  },
  mounted() {
    this.getCompanyListData()
    this.fetchFleetActivities()
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    ...companiesActions,
    validateTechnicalPermission(url) {
      let getPermission = this.technicalPermission
        ? this.technicalPermission?.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    validateOperationalPermission(url) {
      let getPermission = this.operationalPermission
        ? this.operationalPermission?.child.find((menu) =>
            url.includes(menu.url)
          )
        : null
      return getPermission ? getPermission.active : false
    },
    validateReportPermission(url) {
      let getPermission = this.reportPermission
        ? this.reportPermission?.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    async fetchFleetActivities() {
      this.loading = true

      let params = { ...this.params }

      if (params.ownership === 'all') {
        params.ownership = ''
      }

      const checkedFleetTypes = this.checkedFleetTypes()

      params.vehicleTypeId =
        checkedFleetTypes.length > 0 ? checkedFleetTypes.join(',') : ''

      params = this.$options.filters.cleanObject(params)
      let res = await this.getFleetActivities(params)

      this.fleetActivities = []

      const promises = res.data.map(async (vehicle) => {
        // const vehicleExist = this.userVehicles.find(v => v.id === vehicle.id)
        // if (vehicleExist) this.fleetActivities.push(vehicle)
        let newCrewData = []
       
        // const res_crew = await this.getCrewInFleet({ vehicle_id:vehicle.id })

        // const total_crew = res_crew.data.reduce((count, value) => {
        //   if (value.status_emp_crewing.alias === 'active') {
        //     return count + 1;
        //   }
        //   return count;
        // }, 0);

        // res_crew.data.map(obj => {
        //     if (obj.status_emp_crewing.alias === 'active') {
        //       newCrewData.push({
        //         ...obj,
        //         photo: obj?.photo ? obj?.photo_url : obj?.personal_data?.gender === 'Male'
        //             ? require('../../assets/images/user/09.jpg')
        //             : require('../../assets/images/user/11.png'),
        //       })
        //   }
        // })

        this.fleetActivities.push({
          ...vehicle,
          // total_crew,
          // crew_data:newCrewData
        })
      })

      await Promise.all(promises)

      if (res && res.currentPage && res.totalPage) {
        if (res.currentPage === res.totalPage) {
          this.isHideLoadMore = true
        } else {
          this.isHideLoadMore = false
        }
      } else {
        this.isHideLoadMore = true
      }

      this.loading = false
    },
    async fetchFleetActivitiesLoadMore() {
      this.isFetchingLoadMore = true
      this.params.page += 1
      let params = { ...this.params }

      if (params.ownership === 'all') {
        params.ownership = ''
      }

      const checkedFleetTypes = this.checkedFleetTypes()

      params.vehicleTypeId =
        checkedFleetTypes.length > 0 ? checkedFleetTypes.join(',') : ''

      params = this.$options.filters.cleanObject(params)
      
      let res = await this.getFleetActivities(params)

      const promises = res.data.map(async (vehicle) => {
        // const vehicleExist = this.userVehicles.find(v => v.id === vehicle.id)
        // if (vehicleExist) this.fleetActivities.push(vehicle)
        let newCrewData = []
       
        // const res_crew = await this.getCrewInFleet({ vehicle_id:vehicle.id })

        // const total_crew = res_crew.data.reduce((count, value) => {
        //   if (value.status_emp_crewing.alias === 'active') {
        //     return count + 1;
        //   }
        //   return count;
        // }, 0);

        // res_crew.data.map(obj => {
        //     if (obj.status_emp_crewing.alias === 'active') {
        //       newCrewData.push({
        //         ...obj,
        //         photo: obj?.photo ? obj?.photo_url : obj?.personal_data?.gender === 'Male'
        //             ? require('../../assets/images/user/09.jpg')
        //             : require('../../assets/images/user/11.png'),
        //       })
        //   }
        // })

        this.fleetActivities.push({
          ...vehicle,
          // total_crew,
          // crew_data:newCrewData
        })
      })

      await Promise.all(promises)

      if (res && res.currentPage && res.totalPage) {
        if (res.currentPage === res.totalPage) {
          this.isHideLoadMore = true
        } else {
          this.isHideLoadMore = false
        }
      } else {
        this.isHideLoadMore = true
      }

      this.isFetchingLoadMore = false
    },
    async getCompanyListData() {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id,
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    checkedFleetTypes(array) {
      let checked = []

      this.vehicleTypes.forEach((fleet) => {
        if (fleet.checked) {
          checked.push(fleet.id)
        }
      })

      return checked
    },
    async fetchFleetDetail(id) {
      const res = await this.getFleetById(id)

      if (res.status == 'success') {
        this.fleetDetail = res.data
        this.$bvModal.show('edit-fleet')
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
      this.loadingForm = false
    },
    hashObject(obj) {
      return window.btoa(JSON.stringify(obj))
    },
    async onDeleteFleet(dataVal) {
      let data = dataVal

      this.$swal({
        title: 'Delete Fleet?',
        text: `Fleet ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteFleet(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchFleetActivities()
            this.$swal(
              `Fleet deleted!`,
              `Fleet ${data.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async handleOpenMI(data) {
      const forms = await this.getFleetForms({
        vehicleId: data.id,
      })

      if (forms.data) {
        const recommendations = forms.data
          .map((form) => {
            return {
              data: form.recommendations,
              vehicle: data,
            }
          })
          .flat()
        const erm = forms.data
          .map((form) => {
            return {
              data: form.erm,
              vehicle: data,
            }
          })
          .flat()
        const evaluations = forms.data
          .map((form) => {
            return {
              data: form.evaluations,
              vehicle: data,
            }
          })
          .flat()

        this.fleetDetailMiDataModal = {
          ...data,
          forms: forms.data,
          recommendations,
          erm,
          evaluations,
        }

        this.fleetDetailMiDataModal.erm.map((val, index) => {
          this.fleetDetailMiDataModal.forms.map((obj, objIndex) => {
            if(index === objIndex){
              val.type_data = {
                type:obj.type_form,
                date_of_loss: obj.date_of_loss
              }
            }
          })

         return val
        })

        setTimeout(() => {
          this.$root.$emit('bv::toggle::collapse', 'sidebar-mi')
        }, 500)
      }
    },
  },
}
</script>
