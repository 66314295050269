<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List of Crew PKL Not Completed</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12">
              <div  v-if="data?.length > 0 || data?.length > 0" class="d-flex w-100 align-items-center justify-content-between mb-4">
                <b-form-input class="align-self-end w-50 " v-model="searchQuery" placeholder="Search name..."></b-form-input>
                <b-button variant="primary" @click="exportTable()">
                  <div class="d-flex gap-2 align-items-center"> 
                    <i class="fa fa-file-excel"></i> 
                    <span>Export</span>
                  </div>
                </b-button>
              </div>
            </b-col>
            <b-col md="12">
              <table class="table table-bordered" v-if="data?.length > 0">
                <thead>
                  <tr>
                    <th class=" text-center">NO</th>
                    <th class=" text-center">Name</th>
                    <th class=" text-center">Fleet</th>
                    <th class=" text-center">Position</th>
                    <th class=" text-center">Join on Board</th>
                    <th class=" text-center">Sign On</th>
                    <th class=" text-center">Sign Off</th>
                    <th class=" text-center">Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(crew, i) in filteredList" :key="'crew-' + i" >
                    <td class="text-center">{{ i + 1 }}</td>
                    <td>
                      <router-link
                        :to="{
                          path: `profile`,
                          query: {
                            user: 'crew',
                            id: crew?.crewing?.id,
                          },
                        }">{{ crew?.crewing?.fullname ?? '-' }}</router-link><br />
                        <small style="color: gray">NIP : <b>{{ crew?.crewing?.nip ?? '-' }}</b></small>
                    </td>
                    <td class="text-center">{{ crew?.crewing?.vehicle?.name ?? '-' }}
                      <br />
                      <small style="color: gray">{{crew?.crewing?.company?.company ?? '-'}}</small>
                    </td>
                    <td class="text-center">{{ crew?.crewing?.position?.name ?? '-' }} </td>
                    <td class="text-center">{{ crew?.crewing?.join_on_board ? moment(crew?.crewing?.join_on_board).format('DD MMMM YYYY') : '-' }}
                      <b-badge
                        :variant="
                          joinOnBoardStatus(crew) === 'More than 1 year'
                            ? 'success d-block'
                            : joinOnBoardStatus(crew) === 'More than 6 month'
                            ? 'primary d-block'
                            : joinOnBoardStatus(crew) === 'More than 3 month'
                            ? 'warning d-block'
                            : 'dark d-block'
                        "
                        v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                    </td>
                    <td class="text-center">{{ crew?.crewing?.sign_on ? moment(crew?.sign_on).format('DD MMMM YYYY') : '-' }}</td>
                    <td class="text-center">{{ crew?.crewing?.sign_off ? moment(crew?.sign_off).format('DD MMMM YYYY') :  '-' }} <br /> </td>
                    <td class="text-center">
                      <b-badge class="mb-1" :variant="`${crew?.crewing?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                        >{{ crew?.crewing?.status_emp_crewing?.name ?? '-' }}
                      </b-badge>
                      <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                        >{{ expiredContract(crew) }}
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-else class="text-center my-4">List Crew PKL Not Completed doesnt Exist.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SlideListPkl',
  props:{  
    getListPklCrew:{
      type:Function,
      default:true
    },  
    data:{
      type:Array,
      default:[]
    },
  },
  data(){
    return {
      searchQuery:''
    }
  },
  computed: {
    filteredList() {
      const searchLower = this.searchQuery.toLowerCase();
      return this.data?.filter((item) => item?.crewing?.fullname?.toLowerCase().includes(searchLower));
    },
  },
  methods:{
    joinOnBoardStatus(data) {
      if (!data.crewing?.join_on_board) return ''
      if (data.crewing?.working_period > 12) {
        return 'More than 1 year'
      } else if (data.crewing?.working_period > 6) {
        return 'More than 6 month'
      } else if (data.crewing?.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.crewing?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async exportTable (){
      this.loadingExport = true 
      await this.getListPklCrew(true)
      this.loadingExport = false
    }
  }
}
</script>